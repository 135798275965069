import { BaseProxy } from './BaseProxy'

export class CreateManageSubscriptionUrlProxy extends BaseProxy {
  constructor ({ parameters = {} } = {}) {
    super({
      endpoint: 'subscriptions/createManageSubscriptionUrl',
      parameters,
    })
  }
}
