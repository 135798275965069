import * as types from './mutation-types'

export default {
  [types.SET_LANGUAGE] (state, { language }) {
    state.language = language
  },
  [types.SET_DEVICE_ID] (state, { deviceId }) {
    state.deviceId = deviceId
  },
  [types.SET_SESSION_ID] (state, { sessionId }) {
    state.sessionId = sessionId
  },
  [types.SET_ACCESS_TOKEN] (state, { accessToken }) {
    state.accessToken = accessToken
  },
  [types.LOGIN] (state, { accessToken, refreshToken, user }) {
    state.accessToken = accessToken
    state.refreshToken = refreshToken
    state.user = user
  },
  [types.LOGOUT] (state) {
    state.sessionId = null
    state.accessToken = null
    state.refreshToken = null
    state.user = null
  },
  [types.SET_TEMP_USER_DATA] (state, { tempUserData }) {
    state.tempUserData = tempUserData
  },
  [types.SET_FOLLOWING] (state, { following }) {
    state.following = following
  },
  [types.SET_SUBSCRIPTIONS] (state, { subscriptions }) {
    state.subscriptions = subscriptions
  },
  [types.SET_CREDIT_CARD_PACKAGES] (state, { creditCardPackages }) {
    state.creditCardPackages = creditCardPackages
  },
}
