<template>
  <div class="dashboard-container">
    <Menu />
    <div class="views">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import Menu from '@/components/menu/Menu'

export default {
  name: 'Dashboard',
  components: {
    Menu,
  },
}
</script>

<style lang="scss" src="./Dashboard.scss"></style>
