<template>
  <el-dialog
    destroy-on-close
    :fullscreen="isMobile"
    :visible.sync="isVisible"
    :before-close="handleClose"
    custom-class="verify-kit-dialog"
  >
    <loading v-if="isLoading"></loading>
    <div id="verifykit_iframe"></div>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import Loading from '@/components/shared/Loading'
import VerifyKit from '@/utils/VerifyKit'

export default {
  name: 'VerifyKitDialog',
  components: {
    Loading,
  },
  props: {
    isVisible: {
      required: true,
      default: false,
    },
  },
  data () {
    return {
      isLoading: true,
    }
  },
  methods: {
    async initVerifyKit () {
      this.isLoading = true
      try {
        const verifyResponse = await this.verify()
        new VerifyKit(verifyResponse.accessToken, this.handleInitialize, this.handleVerify)
      } catch (error) {
        this.isLoading = false
      }
    },
    handleInitialize () {
      this.isLoading = false
    },
    handleVerify () {
      // eslint-disable-next-line no-undef
      this.login(sessionId)
        .then(() => {
          this.handleClose()
          // this.registerDevice(true)
          this.$router.push({ name: 'dashboard' })
        })
    },
    handleClose () {
      new VerifyKit.removeScript()
      this.$emit('update-visible', false)
    },
    ...mapActions([
      'verify',
      'login',
      'registerDevice',
    ]),
  },
  computed: {
    isMobile () {
      return window.innerWidth < 480
    },
  },
  watch: {
    'isVisible' (val) {
      if (val) {
        this.initVerifyKit()
      }
    },
  },
}
</script>

<style lang="scss" src="./VerifyKitDialog.scss"></style>
