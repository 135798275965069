import { BaseProxy } from './BaseProxy'

export class VerifyProxy extends BaseProxy {
  constructor ({ parameters = {} } = {}) {
    super({
      endpoint: 'verify/webAccessToken',
      parameters,
    })
  }
}
