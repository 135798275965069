<template>
  <div class="following-container">
    <following-card
      v-for="item in following"
      :key="item._id"
      :user="item"
      @handle-unfollow="handleUnfollow"
    >
    </following-card>

    <following-card
      v-if="getFollowedCount < getCanFollowedCount"
      :is-add-new="true"
      :description="!getFollowedCount ? $t('following.no_user_description') : $t('following.click_to_follow')"
      @handle-follow="handleFollow"
    >
    </following-card>

    <el-dialog
      :visible.sync="isDialogVisible"
      :before-close="handleDialogClose"
      custom-class="unfollow-dialog"
    >
      <div class="description">
        {{ $t('following.unfollow_dialog_description', { name: getUnfollowUserName }) }}
      </div>

      <div class="action-buttons">
        <el-button
          round
          type="danger"
          class="outline-button"
          @click="handleDialogClose"
        >
          {{ $t('form.give_up') }}
        </el-button>

        <el-button
          round
          type="success"
          @click="handleApproveUnfollow"
        >
          {{ $t('form.approve') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex'
import FollowingCard from '@/components/following-card/FollowingCard'

export default {
  name: 'Following',
  components: {
    FollowingCard,
  },
  mounted () {
    this.getSubscriptions()
    this.getFollowing()
  },
  data () {
    return {
      unfollowUserId: null,
      isDialogVisible: false,
    }
  },
  methods: {
    handleFollow () {
      this.$router.push({ name: 'follow' })
    },
    handleUnfollow ($event) {
      this.unfollowUserId = $event
      this.isDialogVisible = true
    },
    handleDialogClose () {
      this.unfollowUserId = null
      this.isDialogVisible = false
    },
    handleApproveUnfollow () {
      this
        .unfollow({ monitoredPhoneId: this.unfollowUserId })
        .finally(() => {
          this.getFollowing()
          this.handleDialogClose()
        })
    },
    ...mapActions([
      'getSubscriptions',
      'getFollowing',
      'unfollow',
    ]),
  },
  computed: {
    getUnfollowUserName () {
      if (this.unfollowUserId) {
        return this.following.find(item => item._id === this.unfollowUserId).name
      }
      return null
    },
    ...mapState(['following']),
    ...mapGetters([
      'getFollowedCount',
      'getCanFollowedCount',
    ]),
  },
}
</script>

<style lang="scss" src="./Following.scss"></style>
