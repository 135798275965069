<template>
  <footer v-if="isVisible" class="footer">
    <div class="container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <ul class="footerList">
              <template
                v-if="getLanguageData === 'tr' || getLanguageData === ''"
              >
                <li>
                  <a href="/parentskit-hizmet-sartlari.pdf" target="_blank">{{
                    $t('footer.termsOfService')
                  }}</a>
                </li>
                <li>
                  <a
                    href="/parentskit-gizlilik-politikasi.pdf"
                    target="_blank"
                    >{{ $t('footer.privacyPolicy') }}</a
                  >
                </li>
              </template>
              <template v-else>
                <li>
                  <a href="/parentskit-terms-of-service.pdf" target="_blank">{{
                    $t('footer.termsOfService')
                  }}</a>
                </li>
                <li>
                  <a href="/parentskit-privacy-policy.pdf" target="_blank">{{
                    $t('footer.privacyPolicy')
                  }}</a>
                </li>
              </template>
              <li>
                <a href="mailto:support@parentskit.me">{{ $t('footer.support') }}</a>
              </li>

              {{ getAppVersion }}
            </ul>
            <div class="footerMarket">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/us/app/parentskit-parental-control/id1523999706">
                <img
                  src="../../assets/images/appStore.png"
                  alt="App Store" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.parentskit.app">
                <img
                  src="../../assets/images/googlePlay.png"
                  alt="Google Play" />
              </a>
            </div>
            <div class="footerBottom">
              <ul class="socialLinks">
                <li>
                  <a href="#"
                    ><img src="../../assets/images/social/facebook.png" />
                  </a>
                </li>
                <li>
                  <a href="#"
                    ><img src="../../assets/images/social/twitter.png" />
                  </a>
                </li>
                <li>
                  <a href="#"
                    ><img src="../../assets/images/social/instra.png" />
                  </a>
                </li>
                <li>
                  <a href="#"
                    ><img src="../../assets/images/social/linkedin.png" />
                  </a>
                </li>
              </ul>
              <p class="copyright">
                © 2019 ParentsKit
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  computed: {
    isVisible () {
      return this.$route.name === 'home'
    },
    getLanguageData () {
      return this.$store.getters.getLanguage
    },
    getAppVersion () {
      return `v${process.env.VUE_APP_PROJECT_VERSION}`
    },
  },
}
</script>
