import { v4 as uuidv4 } from 'uuid'
import * as types from './mutation-types'
import { getCookie } from '@/utils'
import { ParentsKit } from '@/utils/request'
import {
  setStorage,
  getStorage,
  hasStorage,
  deleteStorage,
} from '@/utils/localStorageHelper'

import { DevicesProxy } from '@/proxies/DevicesProxy'
import { VerifyProxy } from '@/proxies/VerifyProxy'
import { LoginProxy } from '@/proxies/LoginProxy'
import { LogoutProxy } from '@/proxies/LogoutProxy'
import { TokenProxy } from '@/proxies/TokenProxy'
import { UserProxy } from '@/proxies/UserProxy'
import { TrackProxy } from '@/proxies/TrackProxy'
import { SubscriptionsInfoProxy } from '@/proxies/SubscriptionsInfoProxy'
import { LogsProxy } from '@/proxies/LogsProxy'
import { LogsFilterProxy } from '@/proxies/LogsFilterProxy'
import { LogsChartProxy } from '@/proxies/LogsChartProxy'
import { CreditCardPackagesProxy } from '@/proxies/CreditCardPackagesProxy'
import { BuyWithCreditCardProxy } from '@/proxies/BuyWithCreditCardProxy'
import { VerifyEmailProxy } from '@/proxies/VerifyEmailProxy'
import { CreatePaymentFormUrlProxy } from '@/proxies/CreatePaymentFormUrlProxy'
import { CreateSubscriptionFormUrlProxy } from '@/proxies/CreateSubscriptionFormUrlProxy'
import { CreditCardPackageDetailProxy } from '@/proxies/CreditCardPackageDetailProxy'
import { MailTrackLogDetailProxy } from '@/proxies/MailTrackLogDetailProxy'
import { SmsTrackLogDetailProxy } from '@/proxies/SmsTrackLogDetailProxy'
import { SendOtpProxy } from '@/proxies/SendOtpProxy'
import { CompleteOtpProxy } from '@/proxies/CompleteOtpProxy'
import { SendEmailVerifyLinkProxy } from '@/proxies/SendEmailVerifyLinkProxy'
import { StartEmailVerifyProxy } from '@/proxies/StartEmailVerifyProxy'
import { RedeemProxy } from '@/proxies/RedeemProxy'
import { CancelSubscriptionProxy } from '@/proxies/CancelSubscriptionProxy'
import { SupportSubscriptionProxy } from '@/proxies/SupportSubscriptionProxy'
import { UserInfoDirectProxy } from '@/proxies/UserInfoDirectProxy'
import { PricingProxy } from '@/proxies/PricingProxy'
import { CreateManageSubscriptionUrlProxy } from '@/proxies/CreateManageSubscriptionUrlProxy'

const request = {
  registerDevice: null,
  verify: null,
  login: null,
  logout: null,
  refreshToken: null,
  getUserInfo: null,
  following: null,
  follow: null,
  unfollow: null,
  subscriptions: null,
  userLogs: null,
  userLogsForChart: null,
  creditCardPackages: null,
  checkout: null,
  verifyEmail: null,
  createPaymentFormUrl: null,
  createSubscriptionFormUrl: null,
  creditCardPackageDetail: null,
  mailTrackLogDetail: null,
  smsTrackLogDetail: null,
  sendOtp: null,
  completeOtp: null,
  sendEmailVerifyLink: null,
  startEmailVerify: null,
  redeem: null,
  cancelSubscription: null,
  supportSubscription: null,
  userInfoDirect: null,
  pricing: null,
  createManageSubscriptionUrl: null,
}

export default {
  changeLanguage ({ commit }, language) {
    document.querySelector('html').lang = language
    setStorage({ storageName: 'language', value: language, force: true })
    commit(types.SET_LANGUAGE, { language })
  },
  registerDevice ({ commit, state }, force = false) {
    if (request.registerDevice) return request.registerDevice

    request.registerDevice = new Promise((resolve, reject) => {
      let deviceId
      const storageStatus = hasStorage('deviceId')

      if (storageStatus) {
        deviceId = getStorage('deviceId')
      } else {
        deviceId = uuidv4()
        setStorage({ storageName: 'deviceId', value: deviceId })
      }
      commit(types.SET_DEVICE_ID, { deviceId })

      if (!storageStatus || force) {
        new DevicesProxy()
          .create({
            item: {
              deviceId,
              platform: 3,
              pushId: null,
              googleAdvertisingId: null,
              userId: state.user?._id ?? null,
            },
          })
          .then(resolve)
          .catch(reject)
          .finally(() => {
            request.registerDevice = null
          })
      } else {
        resolve(deviceId)
        request.registerDevice = null
      }
    })

    return request.registerDevice
  },
  verify () {
    if (request.verify) return request.verify

    request.verify = new Promise((resolve, reject) => {
      new VerifyProxy()
        .all()
        .then(resolve)
        .catch(reject)
        .finally(() => {
          request.verify = null
        })
    })

    return request.verify
  },
  login ({ commit, dispatch }, requestBody) {
    if (request.login) return request.login

    request.login = new Promise((resolve, reject) => {
      new LoginProxy()
        .create({ item: requestBody })
        .then(response => {
          commit(types.LOGIN, {
            accessToken: response.token,
            refreshToken: response.user.refreshToken,
            user: {
              _id: response.user._id,
              flow: response.flow,
              info: response.user.info,
              phone: response.user.phone,
              paymentPackages: response.paymentPackages,
              detectedCountry: response.detectedCountry,
            },
          })

          ParentsKit.defaults.headers.country = response.detectedCountry
          if (['sms_has_premium', 'sms'].includes(response.flow)) {
            setStorage({ storageName: 'temporaryAccessToken', value: response.token, force: true })
          } else {
            dispatch('setUserDataToLocalStorage')
          }

          resolve(response)
        })
        .catch(reject)
        .finally(() => {
          request.login = null
        })
    })

    return request.login
  },
  setUserDataToLocalStorage ({ state }) {
    setStorage({ storageName: 'accessToken', value: state.accessToken, force: true })
    setStorage({ storageName: 'refreshToken', value: state.refreshToken, force: true })
    setStorage({ storageName: 'userId', value: state.user._id, force: true })
    setStorage({ storageName: 'detectedCountry', value: state.user.detectedCountry, force: true })
  },
  logout ({ commit, state }) {
    if (request.logout) return request.logout

    request.logout = new Promise((resolve, reject) => {
      new LogoutProxy()
        .create({
          item: { deviceId: state.deviceId },
        })
        .then(() => {
          commit(types.LOGOUT)

          deleteStorage({ storageName: 'accessToken', force: true })
          deleteStorage({ storageName: 'refreshToken', force: true })
          deleteStorage({ storageName: 'userId', force: true })
          window.location.href = '/'

          resolve()
        })
        .catch(reject)
        .finally(() => {
          request.logout = null
        })
    })

    return request.logout
  },
  refreshToken ({ commit }) {
    if (request.refreshToken) return request.refreshToken

    request.refreshToken = new Promise((resolve, reject) => {
      new TokenProxy()
        .create({
          item: {
            userId: getStorage('userId'),
            refreshToken: getStorage('refreshToken'),
          },
        })
        .then(response => {
          commit(types.SET_ACCESS_TOKEN, { accessToken: response.token })

          setStorage({ storageName: 'accessToken', value: response.token, force: true })

          resolve(response)
        })
        .catch(reject)
        .finally(() => {
          request.refreshToken = null
        })
    })

    return request.refreshToken
  },
  getUserInfo ({ commit, state }) {
    if (request.getUserInfo) return request.getUserInfo

    request.getUserInfo = new Promise((resolve, reject) => {
      new UserProxy()
        .all()
        .then(response => {
          const detectedCountry = hasStorage('detectedCountry') ? getStorage('detectedCountry') : 'US'

          commit(types.LOGIN, {
            accessToken: state.accessToken,
            refreshToken: response.refreshToken,
            user: {
              _id: response._id,
              phone: response.phone,
              info: response.info,
              detectedCountry,
            },
          })

          setStorage({ storageName: 'refreshToken', value: response.refreshToken, force: true })
          setStorage({ storageName: 'userId', value: response._id, force: true })
          setStorage({ storageName: 'detectedCountry', value: detectedCountry, force: true })

          resolve(response)
        })
        .catch(reject)
        .finally(() => {
          request.getUserInfo = null
        })
    })

    return request.getUserInfo
  },
  setTempUserData ({ commit }, userData) {
    commit(types.SET_TEMP_USER_DATA, { tempUserData: userData })
  },
  getFollowing ({ commit }) {
    if (request.following) return request.following

    request.following = new Promise((resolve, reject) => {
      new TrackProxy()
        .all()
        .then(response => {
          commit('SET_FOLLOWING', { following: response})
          resolve(response)
        })
        .catch(reject)
        .finally(() => {
          request.following = null
        })
    })

    return request.following
  },
  follow (...args) {
    if (request.follow) return request.follow

    request.follow = new Promise((resolve, reject) => {
      new TrackProxy()
        .create({ item: args[1] })
        .then(resolve)
        .catch(reject)
        .finally(() => {
          request.follow = null
        })
    })

    return request.follow
  },
  unfollow (...args) {
    if (request.unfollow) return request.unfollow

    request.unfollow = new Promise((resolve, reject) => {
      new TrackProxy()
        .delete({ item: args[1] })
        .then(resolve)
        .catch(reject)
        .finally(() => {
          request.unfollow = null
        })
    })

    return request.unfollow
  },
  getSubscriptions ({ commit, state }, isForce = false) {
    if (request.subscriptions) return request.subscriptions

    request.subscriptions = new Promise((resolve, reject) => {
      if (state.subscriptions?.length && !isForce) {
        resolve(state.subscriptions)
      } else {
        new SubscriptionsInfoProxy()
          .all()
          .then(response => {
            commit('SET_SUBSCRIPTIONS', { subscriptions: response})
            resolve(response)
          })
          .catch(reject)
          .finally(() => {
            request.subscriptions = null
          })
      }
    })

    return request.subscriptions
  },
  getUserLogs (...args) {
    if (request.userLogs) return request.userLogs

    request.userLogs = new Promise((resolve, reject) => {
      const proxyInstance = ('page' in args[1]) ? new LogsProxy() : new LogsFilterProxy()
      proxyInstance
        .create({ item: args[1] })
        .then(response => {
          resolve(response)
        })
        .catch(reject)
        .finally(() => {
          request.userLogs = null
        })
    })

    return request.userLogs
  },
  getUserLogsForChart (...args) {
    if (request.userLogsForChart) return request.userLogsForChart

    request.userLogsForChart = new Promise((resolve, reject) => {
      new LogsChartProxy()
        .create({ item: args[1] })
        .then(response => {
          resolve(response)
        })
        .catch(reject)
        .finally(() => {
          request.userLogsForChart = null
        })
    })

    return request.userLogsForChart
  },
  getCreditCardPackages ({ commit, state }) {
    if (request.creditCardPackages) return request.creditCardPackages

    request.creditCardPackages = new Promise((resolve, reject) => {
      if (state.creditCardPackages?.length) {
        resolve(state.creditCardPackages)
      } else {
        new CreditCardPackagesProxy()
          .all()
          .then(response => {
            const creditCardPackages = response
              .sort((a, b) => b.trialPrice - a.trialPrice)
              .sort((a, b) => a.price - b.price)
              .map(item => {
                const foundPrice = item.prices.find(price => price.country === state.user.detectedCountry)
                if (foundPrice) {
                  item.price = foundPrice.price
                  item.currency = foundPrice.currency
                }
                return item
              })

            commit('SET_CREDIT_CARD_PACKAGES', { creditCardPackages })
            resolve(creditCardPackages)
          })
          .catch(reject)
          .finally(() => {
            request.creditCardPackages = null
          })
      }
    })

    return request.creditCardPackages
  },
  checkout ({ state }, checkoutData) {
    if (request.checkout) return request.checkout

    request.checkout = new Promise((resolve, reject) => {
      new BuyWithCreditCardProxy()
        .create({ item: { ...checkoutData, appsFlyerUserId: getCookie('afUserId') } })
        .then(async response => {
          if (state.tempUserData) {
            await this.follow(state.tempUserData)
            this.setTempUserData(null)
          }
          resolve(response)
        })
        .catch(reject)
        .finally(() => {
          request.checkout = null
        })
    })

    return request.checkout
  },
  verifyEmail (...args) {
    if (request.verifyEmail) return request.verifyEmail

    request.verifyEmail = new Promise((resolve, reject) => {
      new VerifyEmailProxy()
        .create({ item: args[1] })
        .then(resolve)
        .catch(reject)
        .finally(() => {
          request.verifyEmail = null
        })
    })

    return request.verifyEmail
  },
  createPaymentFormUrl (...args) {
    if (request.createPaymentFormUrl) return request.createPaymentFormUrl

    request.createPaymentFormUrl = new Promise((resolve, reject) => {
      const {
        token,
        userId,
      } = args[1]

      new CreatePaymentFormUrlProxy()
        .setHeader('Authorization', token)
        .setParameter('userId', userId)
        .create({ item: { ...args[1], appsFlyerUserId: getCookie('afUserId') } })
        .then(resolve)
        .catch(reject)
        .finally(() => {
          request.createPaymentFormUrl = null
        })
    })

    return request.createPaymentFormUrl
  },
  createSubscriptionFormUrl ({ state }, packageId) {
    if (request.createSubscriptionFormUrl) return request.createSubscriptionFormUrl

    request.createSubscriptionFormUrl = new Promise((resolve, reject) => {
      new CreateSubscriptionFormUrlProxy()
        .create({
          item: {
            packageId,
            trackData: state.tempUserData,
            appsFlyerUserId: getCookie('afUserId'),
          },
        })
        .then(resolve)
        .catch(reject)
        .finally(() => {
          request.createSubscriptionFormUrl = null
        })
    })

    return request.createSubscriptionFormUrl
  },
  getCreditCardPackageDetail (...args) {
    if (request.creditCardPackageDetail) return request.creditCardPackageDetail

    request.creditCardPackageDetail = new Promise((resolve, reject) => {
      const {
        token,
        packageId,
      } = args[1]

      new CreditCardPackageDetailProxy()
        .setHeader('Authorization', token)
        .setParameter('packageId', packageId)
        .all()
        .then(resolve)
        .catch(reject)
        .finally(() => {
          request.creditCardPackageDetail = null
        })
    })

    return request.creditCardPackageDetail
  },
  getMailTrackLogDetail (...args) {
    if (request.mailTrackLogDetail) return request.mailTrackLogDetail

    request.mailTrackLogDetail = new Promise((resolve, reject) => {
      const {
        token,
        userId,
      } = args[1]

      new MailTrackLogDetailProxy()
        .setHeader('Authorization', token)
        .setParameter('userId', userId)
        .all()
        .then(resolve)
        .catch(reject)
        .finally(() => {
          request.mailTrackLogDetail = null
        })
    })

    return request.mailTrackLogDetail
  },
  getSmsTrackLogDetail (...args) {
    if (request.smsTrackLogDetail) return request.smsTrackLogDetail

    request.smsTrackLogDetail = new Promise((resolve, reject) => {
      const {
        token,
        userId,
      } = args[1]

      new SmsTrackLogDetailProxy()
        .setHeader('Authorization', token)
        .setParameter('userId', userId)
        .all()
        .then(resolve)
        .catch(reject)
        .finally(() => {
          request.smsTrackLogDetail = null
        })
    })

    return request.smsTrackLogDetail
  },
  sendOtp () {
    if (request.sendOtp) return request.sendOtp

    request.sendOtp = new Promise((resolve, reject) => {
      new SendOtpProxy()
        .create({ item: {} })
        .then(resolve)
        .catch(reject)
        .finally(() => {
          request.sendOtp = null
        })
    })

    return request.sendOtp
  },
  completeOtp ({ dispatch }) {
    if (request.completeOtp) return request.completeOtp

    request.completeOtp = new Promise((resolve, reject) => {
      new CompleteOtpProxy()
        .create({ item: {} })
        .then(response => {
          dispatch('setUserDataToLocalStorage')
          deleteStorage({ storageName: 'temporaryAccessToken', force: true })
          resolve(response)
        })
        .catch(reject)
        .finally(() => {
          request.completeOtp = null
        })
    })

    return request.completeOtp
  },
  sendEmailVerifyLink (...args) {
    if (request.sendEmailVerifyLink) return request.sendEmailVerifyLink

    request.sendEmailVerifyLink = new Promise((resolve, reject) => {
      new SendEmailVerifyLinkProxy()
        .create({ item: args[1] })
        .then(resolve)
        .catch(reject)
        .finally(() => {
          request.sendEmailVerifyLink = null
        })
    })

    return request.sendEmailVerifyLink
  },
  startEmailVerify () {
    if (request.startEmailVerify) return request.startEmailVerify

    request.startEmailVerify = new Promise((resolve, reject) => {

      new StartEmailVerifyProxy()
        .create({ item: {}})
        .then(resolve)
        .catch(reject)
        .finally(() => {
          request.startEmailVerify = null
        })
    })

    return request.startEmailVerify
  },
  redeem (...args) {
    if (request.redeem) return request.redeem

    request.redeem = new Promise((resolve, reject) => {
      const {
        ref,
        token,
        userId,
      } = args[1]

      new RedeemProxy()
        .setHeader('Authorization', token)
        .create({ item: { ref, userId } })
        .then(resolve)
        .catch(reject)
        .finally(() => {
          request.redeem = null
        })
    })

    return request.redeem
  },
  cancelSubscription (...args) {
    if (request.cancelSubscription) return request.cancelSubscription

    request.cancelSubscription = new Promise((resolve, reject) => {
      const {
        token,
        reason,
        packageId,
        subscriptionId,
      } = args[1]

      new CancelSubscriptionProxy()
        .setHeader('Authorization', token)
        .create({ item: { reason, packageId, subscriptionId } })
        .then(resolve)
        .catch(reject)
        .finally(() => {
          request.cancelSubscription = null
        })
    })

    return request.cancelSubscription
  },
  supportSubscription (...args) {
    if (request.supportSubscription) return request.supportSubscription

    request.supportSubscription = new Promise((resolve, reject) => {
      const {
        token,
        type,
        userId,
        reason,
      } = args[1]

      new SupportSubscriptionProxy()
        .setHeader('Authorization', token)
        .create({ item: { type, userId, reason } })
        .then(resolve)
        .catch(reject)
        .finally(() => {
          request.supportSubscription = null
        })
    })

    return request.supportSubscription
  },
  userInfoDirect (...args) {
    if (request.userInfoDirect) return request.userInfoDirect

    request.userInfoDirect = new Promise((resolve, reject) => {
      const {
        token,
        userId,
      } = args[1]

      new UserInfoDirectProxy()
        .setHeader('Authorization', token)
        .create({ item: { userId } })
        .then(resolve)
        .catch(reject)
        .finally(() => {
          request.userInfoDirect = null
        })
    })

    return request.userInfoDirect
  },
  pricing () {
    if (request.pricing) return request.pricing

    request.pricing = new Promise((resolve, reject) => {
      new PricingProxy()
        .all()
        .then(resolve)
        .catch(reject)
        .finally(() => {
          request.pricing = null
        })
    })

    return request.pricing
  },
  createManageSubscriptionUrl (...args) {
    if (request.createManageSubscriptionUrl) return request.createManageSubscriptionUrl

    request.createManageSubscriptionUrl = new Promise((resolve, reject) => {
      const { userId } = args[1]
      new CreateManageSubscriptionUrlProxy()
        .create({ item: { userId } })
        .then(resolve)
        .catch(reject)
        .finally(() => {
          request.createManageSubscriptionUrl = null
        })
    })

    return request.createManageSubscriptionUrl
  },
}
