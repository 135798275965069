<template>
  <div class="email-form-container">
    <div class="title">
      {{ $t('welcome.verify_email_for_get_reports') }}
    </div>

    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      @submit.native.prevent
    >
      <el-form-item prop="email">
        <el-input
          placeholder="example@example.com"
          v-model="form.email"
        >
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-button
          round
          type="success"
          :loading="isLoading"
          @click="handleSendVerifyCodeClick"
        >
          {{ $t('welcome.send_verification_link') }}
        </el-button>
      </el-form-item>
    </el-form>

    <div class="title">
      {{ $t('welcome.or') }}
    </div>

    <div class="info">
      {{ $t('welcome.verify_in_seconds') }}
    </div>

    <el-button
      round
      type="success"
      :loading="isLoading"
      @click="handleSendEmailClick"
    >
      {{ $t('welcome.send_email_and_verify') }}
    </el-button>

    <div class="info">
      {{ $t("welcome.email_verify_body_message") }}
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'EmailForm',
  data () {
    return {
      isLoading: false,
      form: {
        email: '',
      },
      rules: {
        email: [{
          required: true,
          message: this.$t('validation.required_field'),
          trigger: 'submit',
        }],
      },
    }
  },
  created () {
    if (window.AF) {
      window.AF('pba', 'event', { eventType: 'EVENT', eventValue: {}, eventName: 'welcome_email_verify' })
    }
  },
  methods: {
    handleSendVerifyCodeClick () {
      if (this.isLoading) return

      this.isLoading = true

      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            await this.sendEmailVerifyLink(this.form)
            if (window.AF) {
              window.AF('pba', 'event', {
                eventType: 'EVENT',
                eventValue: {},
                eventName: 'welcome_email_verify_completed',
              })
            }
            this.$emit('handle-update-step', 'track-phone-number')
          } catch (error) {
            this.isLoading = false
          }
        } else {
          this.isLoading = false
          return false
        }
      })
    },
    async handleSendEmailClick () {
      if (this.isLoading) return

      this.isLoading = true
      try {
        const response = await this.startEmailVerify()
        const {
          to,
          subject,
        } = response
        window.location.href = `mailto:${to}?subject=${subject}&body=${this.$t('welcome.email_verify_body_message')}`
        this.$emit('handle-update-step', 'track-phone-number')
      } catch (error) {
        this.isLoading = false
      }
    },
    ...mapActions([
      'sendEmailVerifyLink',
      'startEmailVerify',
    ]),
  },
}
</script>

<style lang="scss" src="./EmailForm.scss"></style>
