<template>
  <el-dialog
    :title="$t('subscriptions.cancel_subscription')"
    :fullscreen="isMobile"
    :visible.sync="isVisible"
    :before-close="handleClose"
    custom-class="cancel-subscription-dialog"
  >
  <div class="description">
    {{ $t('subscriptions.cancel_subscription_description') }}
  </div>

  <el-input
    :rows="2"
    type="textarea"
    :placeholder="$t('subscriptions.write_your_cancellation_reason')"
    v-model="cancellationReason">
  </el-input>

  <template slot="footer">
    <el-button
      class="cancel-button"
      :loading="isLoading"
      @click="handleClose"
    >
      {{ $t('form.give_up') }}
    </el-button>
    <el-button
      class="confirm-button"
      type="primary"
      :loading="isLoading"
      @click="handleConfirmClick"
    >
      {{ $t('form.confirm') }}
    </el-button>
  </template>
</el-dialog>
</template>

<script>
export default {
  name: 'CancelSubscriptionDialog',
  props: {
    isLoading: {
      required: true,
      default: false,
    },
    isVisible: {
      required: true,
      default: false,
    },
  },
  data () {
    return {
      cancellationReason: '',
    }
  },
  methods: {
    handleClose () {
      this.$emit('update-visible', false)
      this.cancellationReason = ''
    },
    handleConfirmClick () {
      this.$emit('handle-cancel-subscription', {
        reason: this.cancellationReason.trim(),
      })
      this.cancellationReason = ''
    },
  },
  computed: {
    isMobile () {
      return window.innerWidth < 480
    },
  },
}
</script>

<style lang="scss" src="./CancelSubscriptionDialog.scss"></style>
