import Reports from '@/views/dashboard/reports/Reports'
import Following from '@/views/dashboard/following/Following'
import Subscriptions from '@/views/dashboard/subscriptions/Subscriptions'
// import Profile from '@/views/dashboard/profile/Profile'
import Faq from '@/components/shared/Faq'

const dashboardRoutes = [
  {
    name: 'reports',
    path: 'reports',
    component: Reports,
    meta: {
      title: 'navbar.title.reports',
      requiresAuth: true,
    },
  },
  {
    path: 'following',
    name: 'following',
    component: Following,
    meta: {
      title: 'navbar.title.following',
      requiresAuth: true,
    },
  },
  {
    path: 'subscriptions',
    name: 'subscriptions',
    component: Subscriptions,
    meta: {
      title: 'navbar.title.subscriptions',
      requiresAuth: true,
    },
  },
  /* {
    path: 'profile',
    name: 'profile',
    component: Profile,
    meta: {
      title: 'navbar.profile',
      requiresAuth: true,
    },
  }, */
  {
    path: 'faq',
    name: 'faq',
    component: Faq,
    meta: {
      title: 'navbar.title.faq',
      requiresAuth: true,
    },
  },
]

export { dashboardRoutes }
