import { BaseProxy } from './BaseProxy'

export class LogoutProxy extends BaseProxy {
  constructor ({ parameters = {} } = {}) {
    super({
      endpoint: 'users/logout',
      parameters,
    })
  }
}
