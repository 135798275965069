<template>
  <div class="parentskit-app">
    <navbar></navbar>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <Footer/>
  </div>
</template>

<script>
import { Message } from 'element-ui'
import Adjust from '@adjustcom/adjust-web-sdk'
import Navbar from '@/components/navbar/Navbar'
import Footer from './components/shared/Footer'
import { Broadcast } from '@/utils/Broadcast'

export default {
  components: {
    Navbar,
    Footer,
  },
  created () {
    this.initAdjustSdk()
    Broadcast.$on('response-error', (type, message) => {
      if (message) {
        Message({
          type,
          message,
          duration: 5000,
        })
      }
    })
  },
  methods: {
    initAdjustSdk () {
      Adjust.initSdk({
        appToken: 'feyldxayyyv4',
        environment: 'production',
        logLevel: 'none',
      })
    },
  },
}
</script>
