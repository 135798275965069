<template>
  <div class="pricing-container">
    <div class="pricing-box">
      <div class="country-selection">
        <label>
          {{ $t('pricing.choose_your_country') }}
        </label>

        <el-select
          filterable
          v-model="selectedCountry"
          :placeholder="$t('pricing.choose_your_country')"
        >
          <el-option
            v-for="item in pricingData"
            :key="item.countryCode"
            :label="item.country"
            :value="item.countryCode"
          >
          </el-option>
        </el-select>
      </div>

      <el-table
        class="pricing-table"
        :data="getPricingBySelectedCountry"
      >
        <el-table-column
          prop="name"
          :label="$t('pricing.package_name')"
        >
        </el-table-column>

        <el-table-column
          prop="price"
          :label="$t('pricing.package_price')"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getDefaultDetectedCountry } from '@/utils'

export default {
  name: 'Pricing',
  data () {
    return {
      pricingData: null,
      selectedCountry: getDefaultDetectedCountry(),
    }
  },
  created () {
    this.getPricingData()
  },
  methods: {
    async getPricingData () {
      const loading = this.$loading({
        lock: true,
        text: this.$t('loading_text'),
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: 'loading-style',
      })

      try {
        const response = await this.pricing()
        this.pricingData = Object
          .keys(response)
          .map(key => {
            const pricingObject = response[key]

            return {
              countryCode: key,
              country: pricingObject.Country,
              packages: Object
                .keys(pricingObject)
                .filter(item => item !== 'Country')
                .map(item => ({
                  name: item,
                  price: pricingObject[item],
                })),
            }
          })
          .sort((a, b) => {
            const countryA = a?.country?.toLowerCase()
            const countryB = b?.country?.toLowerCase()

            if (countryA < countryB) return -1
            if (countryA > countryB) return 1
            return 0
          })
        loading.close()
      } catch (error) {
        loading.close()
      }
    },
    ...mapActions(['pricing']),
  },
  computed: {
    getPricingBySelectedCountry () {
      if (this.pricingData) {
        return this.pricingData
          .find(item => item.countryCode === this.selectedCountry)
          .packages
      }
      return []
    },
  },
}
</script>

<style lang="scss" src="./Pricing.scss"></style>
