import { BaseProxy } from './BaseProxy'

export class UserInfoDirectProxy extends BaseProxy {
  constructor ({ parameters = {} } = {}) {
    super({
      endpoint: 'info/user/direct',
      parameters,
    })
  }
}
