<template>
  <div class="verify-email-container">
    <div
      class="message-box"
      v-if="!isLoading"
    >
      <i :class="getInformationIcon"></i>
      <div
        class="information-text"
        v-html="getInformationText"
      >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'VerifyEmail',
  data () {
    return {
      isLoading: true,
      isVerified: null,
      seconds: 5,
    }
  },
  created () {
    if (this.$route.query.code) {
      this.sendRequestFormVerifyEmail()
    } else {
      this.$router.replace({ name: 'home' })
    }
  },
  methods: {
    sendRequestFormVerifyEmail () {
      const loading = this.$loading({
        lock: true,
        text: this.$t('loading_text'),
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: 'loading-style',
      })

      this.verifyEmail({ code: this.$route.query.code })
        .then(() => {
          this.isVerified = true
        })
        .catch(() => {
          this.isVerified = false
        })
        .finally(() => {
          this.isLoading = false
          loading.close()
          const redirectInterval = setInterval(() => {
            if (this.seconds <= 0) {
              clearInterval(redirectInterval)
              this.$router.replace({ name: 'home' })
            } else {
              this.seconds -= 1
            }
          }, 1000)
        })
    },
    ...mapActions(['verifyEmail']),
  },
  computed: {
    getInformationIcon () {
      return this.isVerified
        ? 'success-icon el-icon-circle-check'
        : 'error-icon el-icon-circle-close'
    },
    getInformationText () {
      return this.isVerified
        ? this.$t('verify_email.success_text', { seconds: this.seconds })
        : this.$t('verify_email.error_text', { seconds: this.seconds })
    },
  },
}
</script>

<style lang="scss" src="./VerifyEmail.scss"></style>
