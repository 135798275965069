import axios from 'axios'
import store from '@/store'
import { Broadcast } from '@/utils/Broadcast'
import { warningCodes } from '@/constants/warning-codes'
import { doNotShowCodes } from '@/constants/do-not-show-codes'

import {
  getCookie,
  getDefaultDetectedCountry,
} from '@/utils'

import {
  hasStorage,
  getStorage,
  deleteStorage,
} from '@/utils/localStorageHelper'

const ParentsKit = axios.create({
  baseURL: process.env.VUE_APP_PARENTSKIT_API,
  headers: {
    language: hasStorage('language') ? getStorage('language') : 'en',
    country: getDefaultDetectedCountry(),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    appId: process.env.VUE_APP_PROJECT_ID,
    appVersion: process.env.VUE_APP_PROJECT_VERSION,
    creditCardMode: window.location.hostname === 'localhost' ? 'DEBUG' : 'PROD',
    idfa: '',
  },
})

const Ip = axios.create({
  baseURL: process.env.VUE_APP_IP_API,
  headers: {},
})

const requestInterceptors = [
  [
    function (config) {
      let accessToken
      const appsFlyerUserId = getCookie('afUserId')

      if (appsFlyerUserId) {
        config.headers.common.appsFlyerUserId = appsFlyerUserId
      }

      if (hasStorage('accessToken')) {
        accessToken = getStorage('accessToken')
      }

      if (hasStorage('temporaryAccessToken') && window.location.pathname === '/welcome') {
        accessToken = getStorage('temporaryAccessToken')
      } else {
        deleteStorage({ storageName: 'temporaryAccessToken', force: true })
      }

      if (accessToken) {
        config.headers.common.Authorization = accessToken
      }

      return config
    },
    function (error) {
      return Promise.reject(error)
    },
  ],
]

const responseInterceptors = [
  [
    function (response) {
      return response
    },
    async function (error) {
      if (parseInt(error.response.status, 10) === 401) {
        try {
          await store.dispatch('refreshToken')
        } catch (err) {
          await store.dispatch('logout')
        }
      } else if (error?.response?.data.errorType === 'TokenCantRefreshError') {
        await store.dispatch('logout')
      } else {
        const errorType = error?.response?.data.errorType
        if (!doNotShowCodes.includes(errorType)) {
          Broadcast.$emit(
            'response-error',
            warningCodes.includes(errorType) ? 'warning' : 'error',
            error?.response?.data?.message,
          )
        }
      }
      return Promise.reject(error)
    },
  ],
]

requestInterceptors.forEach(interceptor => {
  ParentsKit.interceptors.request.use(...interceptor)
})

responseInterceptors.forEach(interceptor => {
  ParentsKit.interceptors.response.use(...interceptor)
})

export {
  ParentsKit,
  Ip,
}
