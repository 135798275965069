<template>
  <div :class="['reports-container', { 'no-user': !hasFollowing }]">
    <following-card
      v-if="!hasFollowing"
      :is-add-new="true"
      :description="$t('reports.no_user_description')"
      @handle-follow="handleFollow"
    >
    </following-card>

    <div
      :class="[{ 'no-subscription': !hasActiveSubscription }]"
      v-if="hasFollowing"
    >
      <div class="filter-section">
        <div class="user-selection">
          <el-button
            :class="[{ 'active': selectedUser === item._id }]"
            v-for="item in following"
            :key="item._id"
            :loading="isLoading"
            @click="handleSelectedUserChange(item._id)"
          >
            <i
              class="el-icon-refresh"
              v-if="selectedUser === item._id"
            >
            </i>
            {{ item.name }}
          </el-button>

          <el-button
            v-if="following.length < getCanFollowedCount"
            @click="handleSelectedUserChange('NEW_USER')"
          >
            <i class="el-icon-plus"></i>
          </el-button>

          <el-select
            :value="selectedUser"
            :disabled="isLoading"
            popper-class="user-select-popper"
            @change="handleSelectedUserChange"
          >
            <template slot="prefix">
              <i
                class="el-icon-refresh"
                @click.prevent="handleSelectedUserChange(selectedUser)"
              >
              </i>
            </template>

            <el-option
              v-for="item in following"
              :key="item._id"
              :value="item._id"
              :label="item.name"
            >
            </el-option>

            <el-option
              v-if="following.length < getCanFollowedCount"
              class="follow-new-user"
              value="NEW_USER"
            >
              <i class="el-icon-plus"></i>
            </el-option>
          </el-select>
        </div>

        <el-date-picker
          clearable
          :editable="false"
          :disabled="isLoading"
          class="date-picker"
          format="dd MMM yyyy"
          value-format="yyyy-MM-dd"
          v-model="dateFilter"
          :placeholder="$t('reports.pick_a_date')"
          :picker-options="pickerOptions"
          @change="getTableData(true)"
        >
        </el-date-picker>
      </div>

      <div class="reports-section">
        <el-table
          height="720"
          class="report-table"
          row-class-name="body-row"
          cell-class-name="body-cell"
          header-cell-class-name="header-cell"
          :empty-text="$t('reports.empty_text')"
          v-loading="isLoading"
          :data="getLogs"
        >
          <el-table-column
            align="center"
            :label="$t('reports.online')"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.end">
                {{ scope.row.startTs | formatDate }}
              </span>

              <span v-else>
                {{ $t('reports.online') }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            width="90"
            :label="$t('reports.duration')"
          >
            <template slot-scope="scope">
              <span
                v-if="scope.row.end"
                class="duration"
              >
                {{ scope.row.duration | formatDuration }}
              </span>

              <span v-else> - </span>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            :label="$t('reports.offline')"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.end">
                {{ scope.row.endTs | formatDate }}
              </span>

              <span v-else> - </span>
            </template>
          </el-table-column>
        </el-table>

        <div class="chart-container">
          <line-chart
            class="chart"
            :chart-data="chartData"
          >
          </line-chart>
        </div>
      </div>
    </div>

    <div
      class="no-subscription-container"
      v-if="hasFollowing && !hasActiveSubscription"
    >
      <p v-html="$t('reports.do_not_have_active_subcription')"></p>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex'
import FollowingCard from '@/components/following-card/FollowingCard'
import LineChart from '@/components/line-chart/LineChart'
import dayjs from 'dayjs'
import {
  hasStorage,
  getStorage,
} from '@/utils/localStorageHelper'

export default {
  name: 'Reports',
  components: {
    FollowingCard,
    LineChart,
  },
  filters: {
    formatDate (value) {
      if (value) {
        const today = dayjs()
        const dateValue = dayjs(value)
        const language = hasStorage('language') ? getStorage('language') : 'en'
        if (dateValue.date() === today.date()) {
          return dateValue.locale(language).format('LTS')
        }

        if (dateValue.year() === today.year()) {
          const val = dateValue.locale(language).format('L LTS').split(' ')
          val[0] = val[0].substr(0, 5)
          return val.join(' ')
        }

        return dayjs(value).locale(language).format('L LTS')
      }
    },
    formatDuration (value) {
      if (value) {
        const minutes = Math.floor(value / 60000)
        const seconds = ((value % 60000) / 1000).toFixed(0)
        return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
      }
    },
  },
  data () {
    return {
      isLoading: false,
      page: 1,
      selectedUser: null,
      dateFilter: null,
      tableData: null,
      chartData: {},
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
      },
    }
  },
  created () {
    this.getSubscriptions()
    this.getFollowing()
  },
  methods: {
    checkTableComponent () {
      const interval = setInterval(() => {
        const tableBody = document.querySelector('.el-table__body-wrapper')
        if (tableBody) {
          clearInterval(interval)
          tableBody.onscroll = () => {
            if (tableBody.scrollTop + tableBody.offsetHeight >= tableBody.scrollHeight && this.tableData?.next) {
              this.page = this.page + 1
              this.getTableData()
            }
          }
        }
      }, 100)
    },
    handleSelectedUserChange (id) {
      if (id === 'NEW_USER') {
        this.$router.push({ name: 'follow' })
      } else {
        this.selectedUser = id
        this.page = 1
        this.tableData = null
        this.dateFilter = null
        this.getTableData()
        this.getChartData()
      }
    },
    handleFollow () {
      this.$router.push({ name: 'follow' })
    },
    async getChartData () {
      const language = hasStorage('language') ? getStorage('language') : 'en'
      let data = await this.getUserLogsForChart({
        day: 7,
        monitoredPhoneId: this.selectedUser,
      })
      data = data.days.reverse()
      this.chartData = {
        datasets: [{
          label: this.$t('reports.activity_count'),
          borderColor: '#007aff',
          backgroundColor: '#007aff50',
          fill: false,
          lineTension: 0,
          data: data.map(item => item.count),
          totalDurations: data.map(item => Math.ceil(item.totalDuration/60000)),
        }],
        labels: data.map(item => {
          const date = dayjs(item.date.split('-').reverse().join('-')).locale(language).format('L')
          return `${date}`
        }),
      }
    },
    async getTableData (isReset = false) {
      try {
        this.isLoading = true
        const requestData = this.dateFilter ? {
          date: this.dateFilter,
          monitoredPhoneId: this.selectedUser,
        } : {
          page: this.page,
          monitoredPhoneId: this.selectedUser,
        }

        const data = await this.getUserLogs(requestData)

        if (this.tableData && !isReset) {
          this.tableData = {
            ...data,
            logs: [...this.tableData.logs, ...data.logs],
          }
        } else {
          this.tableData = data
        }

        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    },
    ...mapActions([
      'getSubscriptions',
      'getFollowing',
      'getUserLogs',
      'getUserLogsForChart',
    ]),
  },
  computed: {
    hasActiveSubscription () {
      return this.getActiveSubscription?.active
    },
    getLogs () {
      return this.tableData?.logs ?? []
    },
    ...mapState(['following']),
    ...mapGetters([
      'hasFollowing',
      'getCanFollowedCount',
      'getActiveSubscription',
    ]),
  },
  watch: {
    following (val) {
      if (val?.length) {
        this.selectedUser = val[0]._id
        this.getTableData()
        this.getChartData()
        this.checkTableComponent()

        if (document.getElementsByTagName('html')[0].offsetWidth <= 1024) {
          setTimeout(() => {
            const reportSection = document.getElementsByClassName('reports-section')[0]
            reportSection.style.display = 'block'
            setTimeout(() => {
              reportSection.style.display = 'flex'
            }, 100)
          }, 100)
        }
      }
    },
  },
}
</script>

<style lang="scss" src="./Reports.scss"></style>
