import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/routes'
import qs from 'qs'
import { hasStorage } from '@/utils/localStorageHelper'
import { i18n } from '@/plugins/vue-i18n'

Vue.use(VueRouter)

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  parseQuery (query) {
    return qs.parse(query, {
      decoder (value) {
        if (/^(\d+|\d*\.\d+)$/.test(value)) {
          return parseFloat(value)
        }

        let keywords = {
          true: true,
          false: false,
          null: null,
          undefined: undefined,
        }

        if (value in keywords) {
          return keywords[ value ]
        }

        return value
      },
    })
  },
  stringifyQuery (query) {
    const result = qs.stringify(query, { encodeValuesOnly: true })

    return result ? ('?' + result) : ''
  },
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? `ParentsKit | ${i18n.t(to.meta.title)}` : 'ParentsKit'

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!hasStorage('refreshToken') || !hasStorage('userId')) {
      next('/')
    } else {
      next()
    }
  } else if ((hasStorage('refreshToken') || hasStorage('userId')) && to.name === 'home') {
    next({ name: 'reports' })
  } else {
    next()
  }
})

Vue.router = router

export default {
  router,
}
