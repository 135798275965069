<template>
  <div class="verify-phone-number-form-container">
    <div class="title">
      {{ $t('welcome.verify_phone_number') }}
    </div>

    <div class="subtitle">
      {{ $t('welcome.enter_sms_verification_code') }}
    </div>

    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      @submit.native.prevent
    >
      <el-form-item prop="verifyCode">
        <el-input
          maxlength="4"
          :disabled="isLoading"
          :placeholder="$t('welcome.enter_code_here')"
          v-model="form.verifyCode"
        >
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-button
          round
          type="success"
          :loading="isLoading"
          @click="handleSubmitClick"
        >
          {{ $t('welcome.verify') }}
        </el-button>
      </el-form-item>
    </el-form>

    <el-button
      class="resend-button"
      type="text"
      :loading="isLoading"
      v-html="$t('welcome.resend_verification_code')"
      @click="sendOtpRequest"
    >
    </el-button>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
} from 'vuex'
import { Broadcast } from '@/utils/Broadcast'

export default {
  name: 'VerifyPhoneNumberForm',
  data () {
    return {
      isLoading: false,
      receivedOtpCode: '',
      form: {
        verifyCode: '',
      },
      rules: {
        verifyCode: [{
          required: true,
          message: this.$t('validation.required_field'),
          trigger: 'submit',
        }],
      },
    }
  },
  created () {
    if (window.AF) {
      window.AF('pba', 'event', { eventType: 'EVENT', eventValue: {}, eventName: 'welcome_phone_verify_completed' })
    }

    this.sendOtpRequest()
  },
  methods: {
    async sendOtpRequest () {
      if (this.isLoading) return

      this.isLoading = true

      try {
        const response = await this.sendOtp()
        this.receivedOtpCode = response.otpCode
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    },
    handleSubmitClick () {
      if (this.isLoading) return

      if (this.form.verifyCode !== this.receivedOtpCode) {
        Broadcast.$emit('response-error', 'error', this.$t('errors.invalid_otp_code_error'))
        return false
      }

      this.isLoading = true

      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            await this.completeOtp()
            if (this.user.flow === 'sms') {
              this.$emit('handle-update-step', 'track-phone-number')
            } else {
              this.$router.replace({ name: 'reports' })
            }
          } catch (error) {
            this.isLoading = false
          }
        } else {
          this.isLoading = false
          return false
        }
      })
    },
    ...mapActions([
      'sendOtp',
      'completeOtp',
    ]),
  },
  computed: {
    ...mapState(['user']),
  },
}
</script>

<style lang="scss" src="./VerifyPhoneNumberForm.scss"></style>
