<template>
  <div class="subscriptions-container">
    <div class="phone-number">
      {{ phoneNumber }}
    </div>

    <div class="subscription-info">
      <div class="description">
        {{ $t('subscriptions.description') }}
      </div>

      <div
        class="subscription-management"
        v-if="hasSubscription"
      >
        <div class="next-renewal-date">
          <span v-if="isAutoRenew">{{ $t('subscriptions.next_renewal_date') }}</span>
          <span v-else>{{ $t('subscriptions.expire_date') }}</span>
          <span>{{ getExpireDate | formatDate }}</span>
        </div>

        <div class="payment-type">
          <span>{{ $t('subscriptions.payment_type') }}</span>
          <span>{{ getPaymentType }}</span>
        </div>
      </div>
    </div>

    <div class="title-and-manage-payment">
      <div class="purchased-title">
        {{ $t('subscriptions.subscriptions_purchased') }}
      </div>

      <div class="manage-payment">
        <el-button
          v-if="isPaymentMethodCreditCard"
          type="text"
          :disabled="isDisabledCancelSubscriptionButton"
          @click="handleCancelSubscriptionClick"
        >
          {{ $t('subscriptions.cancel_subscription') }}
        </el-button>

        <el-button
          v-else
          type="text"
          @click="handleManagePaymentPlanClick"
        >
          {{ $t('subscriptions.manage_payment_plan') }}
        </el-button>
      </div>
    </div>

    <div class="purchased-list">
      <div
        class="list-item"
        v-for="item in subscriptions"
        :key="item._id"
      >
        <div>
          {{ $tc('subscriptions.tracking_nubmer_count_info', item.canTrackCount, { count: item.canTrackCount }) }}
        </div>

        <div>
          {{ item.created | formatDate }}
        </div>

        <div>
          {{ item.active ? $t('subscriptions.active') : $t('subscriptions.expired') }}
        </div>

        <el-button
          round
          type="success"
          @click="handleReportProblemClick"
        >
          {{ $t('subscriptions.report_problem') }}
        </el-button>
      </div>

      <div
        class="list-item no-subscription"
        v-if="!hasSubscription"
        @click="handleNoSubscriptionClick"
      >
        {{ $t('subscriptions.do_not_have_active_subcription') }}
      </div>
    </div>

    <CancelSubscriptionDialog
      :isLoading="isLoading"
      :isVisible="isVisibleCancelSubscriptionDialog"
      @handle-cancel-subscription="handleCancelSubscription"
      @update-visible="isVisibleCancelSubscriptionDialog = $event"
    >
    </CancelSubscriptionDialog>

    <ReportProblemDialog
      :isLoading="isLoading"
      :isVisible="isVisibleReportProblemDialog"
      @handle-report-problem="handleReportProblem"
      @update-visible="isVisibleReportProblemDialog = $event"
    >
    </ReportProblemDialog>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex'
import dayjs from 'dayjs'
import {
  hasStorage,
  getStorage,
} from '@/utils/localStorageHelper'
import { Broadcast } from '@/utils/Broadcast'
import CancelSubscriptionDialog from '@/components/cancel-subscription-dialog/CancelSubscriptionDialog'
import ReportProblemDialog from '@/components/report-problem-dialog/ReportProblemDialog'

export default {
  name: 'Subscriptions',
  components: {
    CancelSubscriptionDialog,
    ReportProblemDialog,
  },
  filters: {
    formatDate (value) {
      if (value) {
        const language = hasStorage('language') ? getStorage('language') : 'en'
        return dayjs(value).locale(language).format('LL')
      }
    },
  },
  data () {
    return {
      isLoading: false,
      isVisibleCancelSubscriptionDialog: false,
      isVisibleReportProblemDialog: false,
    }
  },
  created () {
    this.getSubscriptions()
  },
  methods: {
    handleCancelSubscriptionClick () {
      this.isVisibleCancelSubscriptionDialog = true
    },
    async handleCancelSubscription ({ reason }) {
      try {
        this.isLoading = true
        const activeSubscription = this.getActiveSubscription

        await this.cancelSubscription({
          reason,
          token: this.accessToken,
          packageId: this.user.info.subscriptionProductId,
          subscriptionId: activeSubscription._id,
        })
        await this.getSubscriptions(true)
        this.isLoading = false
        this.isVisibleCancelSubscriptionDialog = false
        Broadcast.$emit(
          'response-error',
          'success',
          this.$t('subscriptions.subscription_cancellation_message', {
            expireDate: this.$options.filters.formatDate(activeSubscription.expireDate),
          }),
        )
      } catch (error) {
        this.isLoading = false
        this.isVisibleCancelSubscriptionDialog = false
      }
    },
    handleManagePaymentPlanClick () {
      const subscriptionType = this.user?.info?.subscriptionType
      let link

      if (subscriptionType === 1) {
        link = 'https://dashboard.zotlo.com/'
      } else if (subscriptionType === 2) {
        link = 'https://support.google.com/googleplay/answer/7018481'
      } else {
        link = 'https://support.apple.com/en-us/HT202039'
      }

      window.open(link, '_blank')
    },
    handleReportProblemClick () {
      this.isVisibleReportProblemDialog = true
    },
    async handleReportProblem ({ type, reason }) {
      try {
        this.isLoading = true
        await this.supportSubscription({
          type,
          reason,
          token: this.accessToken,
          userId: this.user._id,
        })
        this.isLoading = false
        this.isVisibleReportProblemDialog = false
      } catch (error) {
        this.isLoading = false
        this.isVisibleReportProblemDialog = false
      }
    },
    handleNoSubscriptionClick () {
      this.$router.push({ name: 'payment' })
    },
    ...mapActions([
      'getSubscriptions',
      'cancelSubscription',
      'supportSubscription',
    ]),
  },
  computed: {
    phoneNumber () {
      return `+${this.user?.phone}` || ''
    },
    isAutoRenew () {
      return !this.getActiveSubscription?.autoRenewDisabled
    },
    getExpireDate () {
      return this.getActiveSubscription?.expireDate
    },
    getPaymentType () {
      const subscriptionType = this.user?.info?.subscriptionType
      let paymentTypeText

      if (subscriptionType === 1) {
        paymentTypeText = this.$t('subscriptions.credit_card')
      } else if (subscriptionType === 2) {
        paymentTypeText = this.$t('subscriptions.google_play')
      } else {
        paymentTypeText = this.$t('subscriptions.itunes')
      }

      return paymentTypeText
    },
    isDisabledCancelSubscriptionButton () {
      return this.getActiveSubscription?.autoRenewDisabled
    },
    isPaymentMethodCreditCard () {
      return this.user?.info?.subscriptionType === 1
    },
    ...mapState([
      'user',
      'accessToken',
      'subscriptions',
    ]),
    ...mapGetters([
      'hasSubscription',
      'getActiveSubscription',
    ]),
  },
}
</script>

<style lang="scss" src="./Subscriptions.scss"></style>
