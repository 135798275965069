import { BaseProxy } from './BaseProxy'

export class SendEmailVerifyLinkProxy extends BaseProxy {
  constructor ({ parameters = {} } = {}) {
    super({
      endpoint: 'v2/email/sendVerifyLink',
      parameters,
    })
  }
}
