import {
  hasStorage,
  getStorage,
} from '@/utils/localStorageHelper'
export default class VerifyKit {
  constructor (token, initializeCallback, successCallback) {
    this.token = token
    this.initializeCallback = initializeCallback
    this.successCallback = successCallback
    if (token && successCallback) {
      this.init()
    }
  }

  init () {
    const firstScript = document.getElementsByTagName('script')[0]
    const script = document.createElement('script')
    let language = 'en'

    if (hasStorage('language')) {
      language = getStorage('language')
    }

    script.src = `https://widget.verifykit.com/v2.1/script.js?lang=${language}&token=${this.token}`

    script.onload = () => {
      // eslint-disable-next-line no-undef
      initVerifyKit(this.successCallback)
      this.initializeCallback()
    }

    if (!firstScript) {
      document.appendChild(script)
    } else {
      firstScript.parentNode.appendChild(script)
    }
  }

  static removeScript () {
    document.getElementsByTagName('script').forEach(item => {
      if(item.src.includes('verifykit')) {
        item.parentNode.removeChild(item)

        const node = document.getElementById('verifykit_iframe')
        node?.querySelectorAll('*').forEach(n => n.remove())
      }
    })
  }
}
