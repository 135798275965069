<template>
  <checkout-landing v-if="flow === 'continue'"></checkout-landing>
</template>

<script>
import { mapActions } from 'vuex'
import { isIphone } from '@/utils'
import CheckoutLanding from '@/components/checkout-landing/CheckoutLanding'

export default {
  name: 'NewCheckout',
  components: {
    CheckoutLanding,
  },
  data () {
    return {
      flow: null,
      loadingAnimation: null,
    }
  },
  created () {
    this.loadingAnimation = this.$loading({
      lock: true,
      text: this.$t('loading_text'),
      background: 'rgba(0, 0, 0, 0.7)',
      customClass: 'loading-style',
    })

    if (isIphone()) {
      this.checkRedeem()
    } else {
      this.flow = 'continue'
    }
  },
  methods: {
    async checkRedeem () {
      try {
        const {
          ref,
          token,
          userId,
        } = this.$route.query
        const response = await this.redeem({ ref, token, userId })

        if (response.flow === 'continue') {
          this.flow = 'continue'
        } else {
          this.flow = 'redeem'
          window.location.href = response.url
        }
        this.loadingAnimation.close()
      } catch (error) {
        this.loadingAnimation.close()
        this.flow = 'continue'
      }
    },
    ...mapActions(['redeem']),
  },
}
</script>
