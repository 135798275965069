import Vue from 'vue'
import VueI18n from 'vue-i18n'
import tr from '@/locale/tr'
import en from '@/locale/en'
import ru from '@/locale/ru'
import ua from '@/locale/ua'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    tr,
    en,
    ru,
    ua,
  },
})

export default {
  i18n,
}
