import Info from '@/components/welcome/info/Info'
import PhoneNumberForm from '@/components/welcome/phone-number-form/PhoneNumberForm'
import VerifyPhoneNumberForm from '@/components/welcome/verify-phone-number-form/VerifyPhoneNumberForm'
import EmailForm from '@/components/welcome/email-form/EmailForm'
import TrackPhoneNumber from '@/components/welcome/track-phone-number/TrackPhoneNumber'
import CheckoutLanding from '@/components/checkout-landing/CheckoutLanding'

const welcomeStepsMap = new Map()
welcomeStepsMap.set('info', Info)
welcomeStepsMap.set('phone-number-form', PhoneNumberForm)
welcomeStepsMap.set('verify-phone-number-form', VerifyPhoneNumberForm)
welcomeStepsMap.set('email-form', EmailForm)
welcomeStepsMap.set('track-phone-number', TrackPhoneNumber)
welcomeStepsMap.set('checkout-landing', CheckoutLanding)

export { welcomeStepsMap }
