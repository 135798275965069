export default {
  isLoggedIn (state) {
    return state.refreshToken && state.user?._id
  },
  hasFollowing (state) {
    return state.following?.length
  },
  getFollowedCount (state) {
    return state.following?.length ?? 0
  },
  hasSubscription (state) {
    return !!state.subscriptions?.find(item => item.active)
  },
  getActiveSubscription (state) {
    return state.subscriptions?.find(item => item.active)
  },
  getCanFollowedCount (state) {
    return state.subscriptions
      ?.find(item => item.active)
      ?.canTrackCount ?? 1
  },
}
