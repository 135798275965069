import { BaseProxy } from './BaseProxy'

export class CreditCardPackageDetailProxy extends BaseProxy {
  constructor ({ parameters = {} } = {}) {
    super({
      endpoint: 'payments/creditCardPackageDetail',
      parameters,
    })
  }
}
