import { BaseProxy } from './BaseProxy'

export class LogsChartProxy extends BaseProxy {
  constructor ({ parameters = {} } = {}) {
    super({
      endpoint: 'stats/logs/chart',
      parameters,
    })
  }
}
