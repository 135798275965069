import { getDefaultDetectedCountry } from '@/utils'
import {
  hasStorage,
  getStorage,
} from '@/utils/localStorageHelper'

export default {
  language: hasStorage('language') ? getStorage('language') : null,
  deviceId: hasStorage('deviceId') ? getStorage('deviceId') : null,
  sessionId: null,
  accessToken: null,
  refreshToken: hasStorage('refreshToken') ? getStorage('refreshToken') : null,
  user: hasStorage('userId') ? {
    _id: getStorage('userId'),
    flow: null,
    info: null,
    phone: null,
    paymentPackages: [],
    detectedCountry: getDefaultDetectedCountry(),
  } : null,
  tempUserData: null,
  following: [],
  subscriptions: [],
  creditCardPackages: [],
}
