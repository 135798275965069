export const getStorageKeys = () => {
  return Object.keys(localStorage)
}

export const hasStorage = (storageName) => {
  return getStorageKeys().includes(storageName)
}

export const clearEmptyStorage = () => {
  getStorageKeys().forEach(item => {
    const storage = localStorage.getItem(item)
    if (storage === '') {
      deleteStorage({ storageName: item })
    }
  })
}

export const setStorage = ({ storageName, value, force = false }) => {
  if (!storageName || !value) {
    throw new Error('Storage key and storage value required!')
  }

  if (!force && hasStorage(storageName)) {
    throw new Error('Storage key already exists!')
  }

  localStorage.setItem(storageName, JSON.stringify(value))
}

export const getStorage = (storageName) => {
  let storage

  try {
    storage = JSON.parse(localStorage.getItem(storageName))
  } catch (error) {
    storage = localStorage.getItem(storageName)
  }

  if (!storage) {
    throw new Error('Storage not found!')
  }

  return storage
}

export const deleteStorage = ({ storageName, force = false }) => {
  if (!force && !hasStorage(storageName)) {
    throw new Error('Storage not found!')
  }

  localStorage.removeItem(storageName)
}

export const clearStorage = () => {
  localStorage.clear()
}

export const addStorageItem = ({ storageName, value }) => {
  const storage = getStorage(storageName)

  if (Array.isArray(storage)) {
    setStorage({
      storageName,
      value: [...storage, value],
      force: true,
    })
  } else if (storage instanceof Object) {
    setStorage({
      storageName,
      value: { ...storage, value },
      force: true,
    })
  } else {
    throw new Error('addItem method not available this storage!')
  }
}

export const updateStorageItem = ({ storageName, updateKey, updateValue, itemKey, itemValue }) => {
  const storage = getStorage(storageName)

  if (Array.isArray(storage)) {
    const storageItem = storage.find(item => item[ itemKey ] === itemValue)
    if (!storageItem) {
      throw new Error('Item not found!')
    }
    storageItem[ updateKey ] = updateValue

    setStorage({
      storageName,
      value: storage,
      force: true,
    })
  } else if (storage instanceof Object) {
    storage[ updateKey ] = updateValue

    setStorage({
      storageName,
      value: storage,
      force: true,
    })
  } else {
    setStorage({
      storageName,
      value: updateValue,
      force: true,
    })
  }
}

export const removeStorageItem = ({ storageName, itemKey, itemValue }) => {
  const storage = this.get(storageName)

  if (Array.isArray(storage)) {
    setStorage({
      storageName,
      value: storage.filter(item => item[ itemKey ] !== itemValue),
      force: true,
    })
  } else if (storage instanceof Object) {
    delete storage[ itemKey ]
    setStorage({
      storageName,
      value: storage,
      force: true,
    })
  } else {
    throw new Error('removeItem method not available this storage!')
  }
}

export const findStorageItem = ({ storageName, itemKey, itemValue }) => {
  const storage = this.get(storageName)

  if (Array.isArray(storage)) {
    return storage.find(item => item[ itemKey ] === itemValue)
  } else {
    throw new Error('findItem method not available this storage!')
  }
}
