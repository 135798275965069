import {
  hasStorage,
  getStorage,
} from './localStorageHelper'

export const getDefaultDetectedCountry = () => {
  if (hasStorage('detectedCountry')) {
    return getStorage('detectedCountry')
  }
  if (navigator.language.includes('-')) {
    return navigator.language.split('-')[1].toUpperCase()
  }
  return navigator.language.toUpperCase()
}

export const getCookie = (cookieKey) => {
  const cookieObj = document.cookie
    .split(';')
    .reduce((acc, item) => {
      const [key, value] = item.split('=')
      acc = { ...acc, [key]: value }

      return acc
    }, {})

  return cookieObj?.[cookieKey]
}

export const isIphone = () => /iPhone/i.test(navigator.userAgent)

export const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
