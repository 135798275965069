<template>
  <div class="homePage">
    <div class="container">
      <div class="homeContent">
        <div class="homeTop">
          <div class="contentWrapper" id="contentWrapper">
            <div class="textBox">
              <h3>{{ $t('home.family') }}<br />{{ $t('home.familyAlt') }}</h3>
              <p>{{ $t('home.familyContent') }}</p>
              <div class="platforms">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.parentskit.app">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="25"
                    viewBox="0 0 22 25"
                  >
                    <g fill="#007AFF">
                      <path
                        d="M19.663 9.075c-.832 0-1.513.68-1.513 1.513v6.05c0 .831.68 1.512 1.513 1.512.831 0 1.512-.68 1.512-1.512v-6.05c0-.832-.68-1.513-1.512-1.513zm-18.15 0C.68 9.075 0 9.755 0 10.588v6.05c0 .831.68 1.512 1.513 1.512.831 0 1.512-.68 1.512-1.512v-6.05c0-.832-.68-1.513-1.512-1.513zm2.268 8.319c0 1.253 1.016 2.269 2.269 2.269v3.025c0 .831.68 1.512 1.513 1.512.831 0 1.512-.68 1.512-1.512v-3.025H12.1v3.025c0 .831.68 1.512 1.513 1.512.831 0 1.512-.68 1.512-1.512v-3.025c1.253 0 2.269-1.016 2.269-2.27V9.076H3.78v8.319zM17.351 7.563c-.23-2.077-1.394-3.872-3.063-4.955l.757-1.513c.187-.374.035-.828-.338-1.015-.374-.187-.828-.035-1.015.338l-.76 1.52-.197-.08c-.675-.224-1.397-.345-2.147-.345-.75 0-1.473.121-2.148.346l-.198.079-.759-1.52C7.296.045 6.842-.107 6.468.08c-.373.187-.525.641-.338 1.015l.757 1.513C5.218 3.692 4.054 5.486 3.824 7.563v.756h13.57v-.756h-.043zM8.32 6.05c-.418 0-.756-.339-.756-.756 0-.418.338-.756.754-.756h.004c.417 0 .755.338.755.756 0 .417-.339.756-.756.756h-.001zm4.537 0c-.417 0-.756-.339-.756-.756 0-.418.337-.756.755-.756h.004c.416 0 .754.338.754.756 0 .417-.339.756-.756.756z"
                        transform="translate(0 .68)"
                      />
                    </g>
                  </svg>
                  Android
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://apps.apple.com/us/app/parentskit-parental-control/id1523999706">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="24"
                    viewBox="0 0 21 24"
                  >
                    <path
                      fill="#007AFF"
                      d="M5.558 23.474c-.472-.314-.89-.7-1.24-1.145-.382-.462-.735-.947-1.06-1.45-.763-1.115-1.36-2.337-1.774-3.623-.498-1.497-.74-2.93-.74-4.331 0-1.565.338-2.93.998-4.076.488-.897 1.216-1.647 2.093-2.174.846-.528 1.833-.817 2.83-.837.349 0 .727.05 1.126.15.29.08.638.209 1.067.368.547.21.846.339.947.369.319.12.588.17.797.17.16 0 .389-.05.643-.13.144-.05.418-.14.807-.309.385-.14.69-.26.932-.349.368-.11.725-.209 1.046-.26.389-.059.774-.08 1.144-.05.708.05 1.355.2 1.934.42 1.016.408 1.836 1.046 2.448 1.952-.26.16-.498.345-.723.548-.485.428-.897.936-1.225 1.5-.429.767-.648 1.634-.642 2.511.015 1.08.289 2.028.837 2.85.385.598.9 1.11 1.528 1.53.309.21.58.354.837.449-.12.373-.251.737-.404 1.096-.346.804-.757 1.575-1.245 2.302-.43.628-.77 1.096-1.026 1.405-.401.478-.787.837-1.176 1.093-.428.284-.932.435-1.447.435-.349.014-.697-.03-1.03-.127-.29-.095-.574-.2-.853-.322-.292-.134-.594-.247-.902-.338-.38-.1-.768-.148-1.16-.147-.399 0-.787.05-1.156.144-.309.088-.608.195-.904.324-.418.174-.693.29-.852.338-.323.096-.654.154-.986.175-.518 0-1.001-.15-1.48-.449l.011-.012zm6.83-18.394c-.678.338-1.321.482-1.966.434-.1-.644 0-1.306.269-2.03.239-.618.558-1.176.996-1.674.458-.518 1.007-.947 1.624-1.256.658-.338 1.286-.518 1.883-.547.08.677 0 1.345-.248 2.062-.228.638-.566 1.226-.997 1.754-.433.518-.971.947-1.58 1.256h.02z"
                    />
                  </svg>
                  iPhone
                </a>
              </div>
              <el-button
                class="tryNow"
                @click="handleTryNowClick"
              >
                {{$t('home.tryNow')}}
              </el-button>
            </div>
            <img src="../assets/images/home/family.png" class="family" />
          </div>
          <a href="#" v-scroll-to="'#homeList'" class="scrollBottom">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="56"
              viewBox="0 0 30 56"
            >
              <g
                fill="none"
                fill-rule="evenodd"
                stroke="#C9CDEE"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                opacity=".9"
                transform="translate(2 2)"
              >
                <rect width="26.1" height="39.6" rx="13.05" />
                <path d="M0 0L5.4 5.4 10.8 0" transform="translate(8.1 46.8)" />
                <path d="M13.05 10.8L13.05 18.9" />
              </g>
            </svg>
          </a>
        </div>
        <div id="homeList" class="homeList">
          <ul class="listing listingLeft">
            <li>
              <h3>{{ $t('home.features.featuresTitleTwo') }}</h3>
              <p>{{ $t('home.features.featuresTextTwo') }}</p>
            </li>
          </ul>
          <ul class="listing listingRight">
            <li>
              <h3>{{ $t('home.features.featuresTitleFive') }}</h3>
              <p>{{ $t('home.features.featuresTextFive') }}</p>
            </li>
          </ul>
        </div>
        <faq id="help"></faq>
      </div>
    </div>

    <verify-kit-dialog
      :isVisible="isVerifyKitDialogVisible"
      @update-visible="isVerifyKitDialogVisible = $event"
    ></verify-kit-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VerifyKitDialog from '@/components/verifyKitDialog/VerifyKitDialog'
import Faq from '../components/shared/Faq'

export default {
  name: 'Home',
  components: {
    VerifyKitDialog,
    Faq,
  },
  data () {
    return {
      isVerifyKitDialogVisible: false,
    }
  },
  methods: {
    handleTryNowClick () {
      // this.isVerifyKitDialogVisible = true
      this.$router.push({
        name: 'welcome',
        query: {
          isnavbarhide: true,
          step: 'phone-number-form',
        },
      })
    },
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
}
</script>
