<template>
  <div class="privacy-policy-container">
    <div class="privacy-policy-box">
      <h2>ParentsKit Privacy Policy</h2>

      <h4>SUMMARY</h4>
      <p>Below is a summary of some key terms of this Privacy Policy. This summary is for your reference only and does not form part of the Privacy Policy.</p>
      <p>We at Jetapps Yazilim Hizmetleri Sanayi Ve Ticaret Limited Şirketi listen to our users and we are committed to protecting the principles of privacy and fair use that underpin our values to offer a transparent and secure service. We want you to be confident that your personal data is safe and secure with us</p>
      <p>
        This Privacy Policy explains the following:
        <ul class="circle">
          <li>how we collect your personal data;</li>
          <li>what personal data we collect;</li>
          <li>how we use your personal data;</li>
          <li>who we share your personal data with; and</li>
          <li>the rights and choices you have when it comes to your personal data.</li>
        </ul>
      </p>

      <h4>Privacy Policy</h4>
      <p>Last modified: 03 June,2020</p>

      <h4>1. Who are we?</h4>
      <p>When we say “ParentsKit” “our,” “we,” or “us,” we’re talking about Jetapps Yazilim Hizmetleri Sanayi Ve Ticaret Limited Şirketi. This Privacy Policy (“Privacy Policy”) applies to all of our apps, services, games, features, software, and website (together, “Services”) unless specified otherwise.</p>
      <p>This Privacy Policy explains how we will use personal data about you and the steps we take to ensure your personal data is kept secure and confidential. It should be read together with our Terms of Service</p>

      <h4>2. How do we collect personal data?</h4>
      <p>We may obtain personal data from you through the websites, mobile applications or other similar devices, channels or applications operated by or on behalf of Jetapps Yazilim Hizmetleri Sanayi Ve Ticaret Limited Şirketi.</p>

      <h4>3. What personal data do we collect?</h4>
      <p>We collect personal data about you when you give this to us in the course of registering for and/or using our Services for example we may collect your name, address, e-mail address or telephone number.</p>
      <p>Note that it is your responsibility to check and ensure that all data, content, material or data you provide us is correct, complete, accurate and not misleading</p>
      <p>
        <i>Data that you may provide voluntarily</i>
      </p>
      <p>
        <b>Certain parts of our Services may ask you to provide personal data voluntarily. For example, we will ask for certain data in order for you to register for an account with us, to begin using our Services, to subscribe to marketing communications from us, and/or to submit enquiries to us, such as:</b>
        <ul class="disc">
          <li>Contact and correspondence data(such as name and email address);</li>
          <li>Username and password;</li>
          <li>Profile data(such as profile photoand biography); and</li>
          <li>Other data you choose to give us (such as data to identify a lost account).</li>
        </ul>
      </p>
      <p>The personal data that you are asked to provide, and the reasons why you are asked to provide it, we will try to explain to you at the point we ask you to provide your personal data.</p>
      <p>
        <i>Data that may be collected automatically</i>
      </p>
      <p>
        <b>When you use our Services, we may also collect data automatically from you and/or your device which may be considered personal data under applicable data protection laws, such as:</b>
        <ul class="disc">
          <li>Data and analytics about your use of our Services;</li>
          <li>Your IP address and mobile device identifiers (such as your device ID, advertising ID, MAC address, IMEI);</li>
          <li>Data about your device, such as device name and operating system, browser type and language;</li>
          <li>Data we collect with cookies and similar technologies;</li>
          <li>Broad geographic location based on your IP address;</li>
          <li>Precise geo-location data (GPS, with your consent); and</li>
          <li>Data about your use of our Services, such as in application interaction and usage metrics.</li>
        </ul>
      </p>
      <p>Advertising ID means Apple Identifier for Advertisers (“IDFA”) on Apple and Google Advertising Identity (“GAID”). These are unique identifiers for mobile device that advertisers use for interest based advertising. They are consistent across all apps and thus allow cross-app tracking. On Apple you can opt-out this by choosing “limit ad tracking” option from privacy settings. On Google Android you can opt-out by choosing “Opt-out of interest based ads”.</p>
      <p>Advertising IDs are non-permanent, non-personal identifiers, which are uniquely associated with your device. Similar technologies include tracking pixels within ads. These technologies allow companies (including marketers and advertisers) to recognise your device when you use websites and applications.</p>
      <p>Data about your device refers to data about your device type, device model, device maker, device operating system and its language or other technical data like screen size or processor, or combination of this data available from a device for a developer for checking the device compatibility with a particular application.</p>
      <p>Some of this data may be collected using cookies and/or similar technologies (such as "SDKs" or software development kits), as explained further below.</p>
      <p>
        <i>Data that we may obtain from third party sources</i>
      </p>
      <p>We may receive personal data about you from third party sources. For example, if you clicked on an advertisement to direct you to one of our Services we maybe provided with data from which ad-network and advertising campaign the install originated from.</p>
      <p>
        You may choose to connect to our Services via your social media account. Exactly what data we receive from your social media will depend on your social media privacy settings, but it would typically include your basic public profile data such as:
        <ul class="disc">
          <li>your username;</li>
          <li>profile picture;</li>
          <li>age range; and</li>
          <li>gender</li>
        </ul>
      </p>
      <p>
        The data that we may collect from our partners could include;
        <ul class="disc">
          <li>Data we receive if you link a third-party tool with our Services (such as Facebook, Apple or Google);</li>
          <li>Demographic data (such as to determine the location of your IP address);</li>
          <li>Data to fight fraud (such as click fraud in advertising);</li>
          <li>Data from platforms that our Services run on (such as to verify payment); and</li>
          <li>Data for advertising and analytics purposes, so we can optimise the Services we deliver to you.</li>
        </ul>
      </p>
      <p>We do not request or intend to collect any specialor sensitive categories of data such as any data on health, race, religion, political opinions, sexual preferences or orientation.</p>

      <h4>4. How do we use your personal data?</h4>
      <p>
        We may use your personal data:
        <ol>
          <li>
            to <b>enable you to access and use</b> the Services. For example;
            <ul class="disc">
              <li>To create user accounts and profiles;</li>
              <li>To provide you with the correct versions of our applications and/or games; and</li>
              <li>To send you service related communications including confirmations, technical notices, updates, security alerts, and support or administrative messages</li>
            </ul>
          </li>
          <li>
            to <b>personalise and improve</b> aspects of our Services. For example;
            <ul class="disc">
              <li>To respond to your customer support requests;</li>
              <li>To receive error messages about any issues you / your device encounters with our Services to enable us to fix bugs and interoperability issues;</li>
              <li>To detecting and preventing illegal activities;</li>
              <li>To track application installs, for purposes of measuring the effect of our marketing campaigns;</li>
              <li>To conduct optional user feedback surveys;</li>
              <li>To remember you next time you visit one of our Services; and</li>
              <li>Communicate with you about promotions, rewards, upcoming events, and other news about products and services offered by us.</li>
            </ul>
          </li>
          <li>
            <b>to communicate</b> with you, including some or all of the following:
            <ul class="disc">
              <li>To provide you with in-appoffers and rewards based on your interaction with our Services;</li>
              <li>To serve you with relevant targeted advertisements;</li>
              <li>To make you offers to purchase in-appitems on discount based on your activity with our application and your purchase history; and</li>
              <li>To send you marketing communications and/or newsletters if you have subscribed for them about rewards and promotions.</li>
            </ul>
          </li>
          <li>for <b>research</b>, such as analysing market trends and customer demographics;</li>
          <li>To <b>comply</b> with legal obligations, including to prevent and/or detect fraud or provide disclosure to the authorities when required by applicable law.</li>
          <li>To create application ads with the use of photos that were provided to our application by you</li>
        </ol>
      </p>

      <h4>5. Why do we process your personal data?</h4>
      <p>
        We will only collect and use your personal data(as described in section 4) in accordance with data protection laws. Our grounds for processing your personal dataare as follows:
        <ol>
          <li><b>Consent –</b> Where necessary we will only collect and process your personal data if you have given your clear and affirmative consent for us to do so.</li>
          <li><b>Legitimate Interests –</b> We may use and process some of your personal data where we have sensible and legitimate business grounds for doing so. Under European privacy laws there is a concept of “legitimate interests” as a justification for processing your personal data. Our legitimate interests for processing your personal data are for us to enable you access and use of our Services, to communicate with you about our Services and to improve our Services.</li>
        </ol>
      </p>

      <h4>6. Who do we share your personal data with?</h4>
      <p>
        When you use any of our Services, we may disclose your personal data to the following parties:
        <ol type="a">
          <li>To our group companies, third party services providers and partners who provide data processing services to us. For example, to support the delivery of, provide functionality on, or help to enhance the security of our Services, or who otherwise process personal data for purposes that are described in this Privacy Policy or notified to you when we collect your personal data.</li>
          <li>to any competent law enforcement body, regulatory, government agency, court or other third party where we believe disclosure is necessary.</li>
          <li>to anyother person with your consent to the disclosure.</li>
        </ol>
      </p>

      <h4>7. What cookies do we use?</h4>
      <p>A cookie is a very small text file placed on your computer or device. We and our partners use cookies and similar technologies to provide and personalise our Services, analyse use, target advertisements and prevent fraud. You can disable cookies in your browser settings, but some parts of our Services may then not function properly.</p>

      <h4>8. What advertisements do we show on our Services?</h4>
      <p>The advertisements that you see displayed on our Services are delivered on our behalf by certain third-party advertising companies. No data which identifies you, for example your name, address, email address or telephone number, is used to provide this advertising. In order to display advertising most likely to be relevant to you, these third-party advertisers may place or recognise a unique cookie on your browser. This cookie does not collect personal dataabout you nor is it linked to any identifiable data about you. By using our Services,you are consenting to the use of these third-party cookies for these purposes.</p>
      <p>If you would like more data about online advertisements and your choices about how this data may be used by these companies, please find more data here <a target="_blank" href="http://www.youronlinechoices.com/uk/your-ad-choices">http://www.youronlinechoices.com/uk/your-ad-choices</a>.</p>

      <h4>9. What steps do we take to keep your personal data safe?</h4>
      <p>Your personal data’s security is very important to us. This is why, where it’s appropriate, we use HTTPS to help keep data about you secure. However, no data transmission over the internet can be guaranteed to be totally secure.</p>
      <p>We do our best to keep the data you disclose to us secure. However, we can't guarantee or warrant the security of any data which you send to us, and you do so at your own risk. By using our Services, you accept the inherent risks of providing data online and will not hold us responsible for any breach of security.</p>

      <h4>10. How long do we keep your personal data?</h4>
      <p>Unless a longer retention period is required or permitted by law, we will only retain your personal data only for as long as reasonably necessary to fulfil the purposes outlined in this Privacy Policy and for our legitimate business interests, such as to comply with our legal obligations, resolve disputes, and enforce our agreements. We will for example periodically de-identify unused user accounts and regularly review our data sets.</p>

      <h4>11. Do we transfer your personal data outside of the country you are resident?</h4>
      <p>Our Services are global by nature and your personal data may be transferred to, and processed in, countries other than the country in which you are resident. These countries may have data protection laws that are different to the laws of your country. We take steps to ensure adequate safeguards are in place to protect your data as explained in our Privacy Policy. Adequate safeguards that our partners may use include standard contractual clauses approved by EU Commission and the Privacy Shield certification in case of transfers to the USA.</p>

      <h4>12. Age Limits</h4>
      <p>We do not knowingly collect or solicit personal data about or direct or target interest based advertising to anyone under the age of 13 or knowingly allow such persons to use our Services. If you are under 13, please do not send any data about yourself to us, including your name, address, telephone number, or email address. No one under the age of 13 should provide us any personal data. If we learn that we have collected personal data about a child under age 13, we will delete that data as soon as it is practicably possible.</p>

      <h4>13. Your personal data rights and how to contact us</h4>
      <p>
        You have certain rights under existing data protection legislation including the right to request a copy of the personal data we hold about you, if you request it from us in writing.
        <ol type="i">
          <li><b>Right to access:</b> the right to request copies of your personal data from us;</li>
          <li><b>Right to correct:</b> the right to have your personal data rectified if it is inaccurate or incomplete;</li>
          <li><b>Right to erase:</b> the right to request that we delete or remove your personal data from our systems;</li>
          <li><b>Right to restrict our use of your data:</b> the right to ‘block’ us from using your personal data or limit the way in which we can use it;</li>
          <li><b>Right to data portability:</b> the right to request that we move, copy or transfer your personal data;</li>
          <li><b>Right to object:</b> the right to object to our use of your personal data ncluding where we use it for our legitimate interests.</li>
        </ol>
      </p>
      <p>To make enquiries, exercise any of your rights set out in this Privacy Policy and/or make a complaint please contact us at <a href="mailto:support@parentskit.me">support@parentskit.me</a></p>

      <h4>Changes to this Privacy Policy</h4>
      <p>We reserve the right to amend or modify this Privacy Policy at any time and any changes will be published on our Services. The date of the most recent revision will appear on this page. If we make significant changes to this policy, we may also notify you by other means such as sending an email. Where required by law we will obtain your consent to make these changes. If you do not agree with any changes, please do not continue to use our Services.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
}
</script>

<style lang="scss" src="./PrivacyPolicy.scss"></style>
