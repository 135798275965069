<template>
  <div class="follow-container">
    <el-form
      ref="form"
      label-position="top"
      :model="form"
      :rules="rules"
      @submit.native.prevent
    >
      <el-form-item
        prop="name"
        :label="$t('form.name_surname')"
      >
        <el-input
          :minlength="1"
          :disabled="isLoading"
          v-model="form.name"
        >
        </el-input>
      </el-form-item>

      <el-form-item
        prop="phoneNumber"
        :label="$t('form.phone_number')"
      >
        <vue-phone-number-input
          show-code-on-list
          :disabled="isLoading"
          :error="isPhoneNumberError"
          v-model.trim="form.phoneNumber"
          :default-country-code="getDefaultCountry"
          @update="handlePhoneNumberChange"
        >
        </vue-phone-number-input>
      </el-form-item>

      <el-form-item>
        <el-button
          round
          type="success"
          :loading="isLoading"
          @click="handleSubmitClick"
        >
          {{ $t('form.save') }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getDefaultDetectedCountry } from '@/utils'

export default {
  name: 'Follow',
  data () {
    return {
      isLoading: false,
      form: {
        name: '',
        phoneNumber: '',
      },
      rules: {
        name: [{
          required: true,
          message: this.$t('validation.required_field'),
          trigger: 'submit',
          validator: (rule, value, callback) => {
            if (!value.trim().length) {
              callback(new Error(this.$t('validation.required_field')))
            }
            callback()
          },
        }],
        phoneNumber: [{
          required: true,
          message: this.$t('validation.required_field'),
          trigger: 'submit',
        }],
      },
      phoneObj: null,
    }
  },
  methods: {
    handlePhoneNumberChange (val) {
      this.phoneObj = val
    },
    handleSubmitClick () {
      if (this.isLoading) return

      this.isLoading = true

      this.$refs.form.validate(async (valid) => {
        if (valid && !this.isPhoneNumberError) {
          const requestData = {
            name: this.form.name.trim(),
            countryCode: this.phoneObj.countryCode.toLowerCase(),
            phone: `${this.phoneObj.countryCallingCode}${this.phoneObj.nationalNumber}`,
          }
          try {
            await this.follow(requestData)
            this.isLoading = false
            this.$router.replace({ name: 'reports' })
          } catch (error) {
            if (error.errorType === 'NumberTrackingSubscriptionNeededError') {
              this.setTempUserData(requestData)
              this.$router.replace({ name: 'payment' })
            }
            this.isLoading = false
          }
        } else {
          this.isLoading = false
          return false
        }
      })
    },
    ...mapActions([
      'setTempUserData',
      'follow',
    ]),
  },
  computed: {
    isPhoneNumberError () {
      return this.phoneObj?.phoneNumber?.length && !this.phoneObj?.isValid
    },
    getDefaultCountry () {
      return getDefaultDetectedCountry()
    },
  },
}
</script>

<style lang="scss" src="./Follow.scss"></style>
