<template>
  <div class="payment-container">
    <div class="section subscription-info">
      <div class="selected-package-info">
        <div class="app-name">
          Parents Kit
        </div>

        <template v-if="isTrialPackage">
          <div class="trial-text">
            {{ $t('payment.trial_text', {
                day: getTrialPackageInfo.day,
                price: getTrialPackageInfo.price
              })
            }}
          </div>

          <div class="then">
            {{ $t('payment.then') }}
          </div>
        </template>

        <div class="subscription-price">
          {{ getSelectedPackageText }}
        </div>

        <div class="cancel-change-package">
          <div>
            {{ $t('payment.cancel_package_text') }}
          </div>

          <el-button
            type="text"
            @click="isDialogVisible = true"
          >
            {{ $t('payment.change_plan') }}
          </el-button>
        </div>
      </div>
    </div>

    <div class="section payment-form">
      <div class="card-info">
        {{ $t('payment.card_info') }}
      </div>

      <div class="card-types"></div>

      <el-form
        ref="form"
        label-position="top"
        :model="form"
        :rules=rules
        @submit.native.prevent
      >
        <div>
          <el-form-item
            prop="cardOwner"
            :label="$t('form.cardholder_name')"
          >
            <el-input
              :disabled="isLoading"
              v-model="form.cardOwner"
            >
            </el-input>
          </el-form-item>

          <el-form-item
            prop="cardNo"
            :label="$t('form.card_number')"
          >
            <el-input
              minlength="13"
              maxlength="16"
              :disabled="isLoading"
              v-model="form.cardNo"
            >
            </el-input>
          </el-form-item>
        </div>

        <div>
          <el-form-item
            prop="expireDate"
            :label="$t('form.expire_date')"
          >
            <el-input
              minlength="5"
              maxlength="5"
              :disabled="isLoading"
              :placeholder="$t('form.month_year')"
              v-mask="'##/##'"
              v-model="form.expireDate"
            >
            </el-input>
          </el-form-item>

          <el-form-item
            prop="cvv"
            :label="$t('form.security_code')"
          >
            <el-input
              minlength="3"
              maxlength="4"
              :disabled="isLoading"
              :placeholder="$t('form.cvv')"
              v-mask="['###', '####']"
              v-model="form.cvv"
            >
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              round
              type="success"
              :loading="isLoading"
              @click="handleSubmitClick"
            >
              {{ $t('form.start_subscription') }}
            </el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="$t('payment.select_membership_plan')"
      :visible.sync="isDialogVisible"
      :fullscreen="isMobile"
      custom-class="membership-plan-dialog"
    >
      <div
        v-for="item in creditCardPackages"
        :key="item.packageId"
        :class="[
          'membership-plan-item monthly',
          { 'monthly': item.period === 30, 'yearly': item.period === 365 }
        ]"
        @click="handlePlanChange(item.packageId)"
      >
        <div class="package-name">
          {{ item.name }}
        </div>

        <div class="price">
          <template v-if="item.trialPeriod">
            {{ $t('payment.trial_text', { day: item.trialPeriod, price: item.trialPrice}) }}
            {{ $t('payment.then') }}
            {{ item.price }} {{ item.currency }}
          </template>
          <template v-else>
            {{ item.price }} {{ item.currency }}
          </template>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
} from 'vuex'
import Adjust from '@adjustcom/adjust-web-sdk'
import { mask } from 'vue-the-mask'

const {
  VUE_APP_FULLNAME_PATTERN,
  VUE_APP_CREDITCARD_NUMBER_PATTERN,
  VUE_APP_EXPIRE_DATE_PATTERN,
  VUE_APP_CVV_PATTERN,
} = process.env

export default {
  name: 'Payment',
  directives: { mask },
  data () {
    return {
      isLoading: false,
      selectedPackage: null,
      isDialogVisible: false,
      form: {
        cardOwner: null,
        cardNo: null,
        expireDate: null,
        cvv: null,
      },
      rules: {
        cardOwner: [{
          required: true,
          pattern: new RegExp(VUE_APP_FULLNAME_PATTERN),
          message: this.$t('validation.invalid_name_surname'),
          trigger: 'submit',
        }],
        cardNo: [{
          required: true,
          pattern: new RegExp(VUE_APP_CREDITCARD_NUMBER_PATTERN),
          message: this.$t('validation.invalid_card_number'),
          trigger: 'submit',
        }],
        expireDate: [{
          required: true,
          pattern: new RegExp(VUE_APP_EXPIRE_DATE_PATTERN),
          message: this.$t('validation.invalid_expire_date'),
          trigger: 'submit',
        }],
        cvv: [{
          required: true,
          pattern: new RegExp(VUE_APP_CVV_PATTERN),
          message: this.$t('validation.invalid_cvv'),
          trigger: 'submit',
        }],
      },
    }
  },
  created () {
    Adjust.trackEvent({
      eventToken: '26mdn5',
    })

    if (this.creditCardPackages.length) {
      this.selectedPackage = this.creditCardPackages[0].packageId
    } else {
      this.getCreditCardPackages()
    }
  },
  methods: {
    handlePlanChange (packageId) {
      this.selectedPackage = packageId
      this.isDialogVisible = false
    },
    async handleSubmitClick () {
      if (this.isLoading) return

      this.isLoading = true

      this.$refs.form.validate(async (valid) => {
        if (valid) {
          Adjust.trackEvent({
            eventToken: 'g5x6sx',
          })

          try {
            await this.checkout({
              cardOwner: this.form.cardOwner,
              cardNo: this.form.cardNo,
              expireMonth: this.form.expireDate.split('/')[0],
              expireYear: this.form.expireDate.split('/')[1],
              cvv: this.form.cvv,
              packageId: this.selectedPackage,
            })

            const selectedPackageInfo = this.creditCardPackages.find(item => item.packageId === this.selectedPackage)
            window.gtag('event', 'conversion', {
              send_to: 'AW-802517625/MA2ICO3bxIsCEPnk1f4C',
              value: selectedPackageInfo.price ?? '0.0',
              currency: selectedPackageInfo.currency ?? 'USD',
              transaction_id: '',
            })

            Adjust.trackEvent({
              eventToken: 'g8221w',
            })

            Adjust.trackEvent({
              eventToken: 'eqk7mj',
            })

            this.$router.push({ name: 'reports' })
          } catch (error) {
            Adjust.trackEvent({
              eventToken: 'x8i183',
            })

            Adjust.trackEvent({
              eventToken: 'qeyylr',
            })
          } finally {
            this.isLoading = false
          }
        } else {
          this.isLoading = false
        }
      })
    },
    ...mapActions([
      'getCreditCardPackages',
      'checkout',
    ]),
  },
  computed: {
    isMobile () {
      return window.innerWidth < 480
    },
    isTrialPackage () {
      if (this.selectedPackage) {
        const foundPackage = this.creditCardPackages.find(item => item.packageId === this.selectedPackage)
        return foundPackage.trialPeriod !== 0
      }
      return false
    },
    getTrialPackageInfo () {
      if (this.selectedPackage) {
        const foundPackage = this.creditCardPackages.find(item => item.packageId === this.selectedPackage)
        return {
          day: foundPackage.trialPeriod,
          price: `${foundPackage.trialPrice} ${foundPackage.currency}`,
        }
      }
      return {}
    },
    getSelectedPackageText () {
      if (this.selectedPackage) {
        const foundPackage = this.creditCardPackages.find(item => item.packageId === this.selectedPackage)
        return `${foundPackage.name} ${foundPackage.price} ${foundPackage.currency}`
      }
      return null
    },
    ...mapState(['creditCardPackages']),
  },
  watch: {
    creditCardPackages (val) {
      if (val) {
        this.selectedPackage = val[0].packageId
      }
    },
  },
}
</script>

<style lang="scss" src="./Payment.scss"></style>
