import { BaseProxy } from './BaseProxy'

export class TokenProxy extends BaseProxy {
  constructor ({ parameters = {} } = {}) {
    super({
      endpoint: 'users/token',
      parameters,
    })
  }
}
