<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  data () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          align: 'start',
          labels: {
            boxWidth: 0,
            fontFamily: 'Nunito',
            fontColor: '#007aff',
          },
        },
        scales: {
          xAxes: [{
            distribution: 'linear',
            scaleLabel: {
              display: true,
              labelString: `${this.$t('reports.date')}`,
              fontFamily: 'Nunito',
              fontColor: '#007aff',
            },
          }],
          yAxes: [{
            ticks: {
              min: 0,
              stepSize: 5,
            },
          }],
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const entryCount = `${data.datasets[0].label}: ${tooltipItem.value}`
              const duration = data.datasets[0].totalDurations[tooltipItem.index]
              const totalDuration = `${this.$t('reports.total_duration')}: ${duration} ${this.$t('reports.minutes')}`
              return `${entryCount}  ${totalDuration}`
            },
          },
        },
      },
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
}
</script>
