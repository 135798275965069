export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_DEVICE_ID = 'SET_DEVICE_ID'
export const SET_SESSION_ID = 'SET_SESSION_ID'
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const SET_TEMP_USER_DATA = 'SET_TEMP_USER_DATA'
export const SET_FOLLOWING = 'SET_FOLLOWING'
export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS'
export const SET_CREDIT_CARD_PACKAGES = 'SET_CREDIT_CARD_PACKAGES'
