<template>
  <div class="checkout-landing-container">
    <div
      class="checkout-landing-box"
      v-if="!isLoading"
    >
      <div class="bold-text">
        {{ getUserPhone }}
      </div>

      <div class="bold-text">
        {{ $t('checkout_landing.whatsapp_activities') }}
      </div>

      <lottie-animation
        class="report-animation"
        path="./animation/report-animation.json"
      >
      </lottie-animation>

      <div class="report-text bold-text">
        {{ $t('checkout_landing.continue_to_view_reports') }}
      </div>

      <div class="description">
        <div>{{ $t('checkout_landing.description_online_time') }}</div>
        <div>{{ $t('checkout_landing.description_notification') }}</div>
      </div>

      <div
        class="trial-package-detail-text"
        v-if="isWelcomeFlow && isTrialPackage"
      >
        {{
          $t('checkout_landing.trial_package_detail', {
            day: packageData.trialPeriod,
            period: getTrialPeriodTypeText,
            price: packageData.trialPrice,
            currency: packageData.currency,
          })
        }}
      </div>

      <el-button
        round
        type="success"
        class="view-reports-button"
        v-html="getButtonText"
        @click="handleCheckoutButtonClick"
      >
      </el-button>

      <div
        class="discount-text"
        v-if="isWelcomeFlow && !isTrialPackage"
        v-html="getPackageDiscountText"
      >
      </div>

      <div
        class="expire-text"
        v-if="isWelcomeFlow"
      >
        {{ getExpireText }}
      </div>

      <div
        class="refund-text"
        v-if="isWelcomeFlow"
      >
        {{ $t('checkout_landing.refund_text') }}
      </div>

      <div
        class="after-trial-package-detail-text"
        v-if="isWelcomeFlow && isTrialPackage"
      >
        {{ getAfterTrialPackageDetailText }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
} from 'vuex'
import Adjust from '@adjustcom/adjust-web-sdk'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation'

export default {
  name: 'CheckoutLanding',
  components: {
    LottieAnimation,
  },
  data () {
    return {
      isLoading: true,
      loadingAnimation: null,
      userData: null,
      packageData: null,
      checkoutFormUrl: null,
    }
  },
  created () {
    this.loadingAnimation = this.$loading({
      lock: true,
      text: this.$t('loading_text'),
      background: 'rgba(0, 0, 0, 0.7)',
      customClass: 'loading-style',
    })

    if (this.isWelcomeFlow) {
      if (window.AF) {
        window.AF('pba', 'event', { eventType: 'EVENT', eventValue: {}, eventName: 'welcome_view_reports' })
      }

      this.prepareDataForSubscription()
    } else {
      this.prepareDataForPayment()
    }
  },
  methods: {
    prepareDataForPayment () {
      const {
        ref,
        token,
        userId,
        packageId,
      } = this.$route.query

      if (ref === 'sms') {
        Adjust.trackEvent({
          eventToken: '71z7j5',
        })
      } else {
        Adjust.trackEvent({
          eventToken: '26mdn5',
        })
      }

      const userDataRequestMethod = ref === 'sms' ? this.getSmsTrackLogDetail : this.getMailTrackLogDetail

      Promise
        .all([
          this.createPaymentFormUrl({
            ref,
            token,
            userId,
            packageId: packageId ? packageId : 'cc_one_number_monthly_introductory_price',
          }),
          userDataRequestMethod({ token, userId }),
        ])
        .then(([checkoutFormUrl, userData]) => {
          this.checkoutFormUrl = checkoutFormUrl
          this.userData = userData

          this.loadingAnimation.close()
          this.isLoading = false
        })
        .catch(() => {
          this.loadingAnimation.close()
          this.$router.replace({ name: 'home' })
        })
    },
    async prepareDataForSubscription () {
      Promise
        .all([
          this.getCreditCardPackages(),
          this.createSubscriptionFormUrl(this.user.paymentPackages[0]),
        ])
        .then(([packageData, checkoutFormUrl]) => {
          this.packageData = packageData.find(item => item.packageId === this.user.paymentPackages[0])
          this.checkoutFormUrl = checkoutFormUrl

          this.loadingAnimation.close()
          this.isLoading = false
        })
        .catch(() => {
          this.loadingAnimation.close()
          this.isLoading = false
        })
    },
    handleCheckoutButtonClick () {
      if (this.isWelcomeFlow && window.AF) {
        window.AF('pba', 'event', { eventType: 'EVENT', eventValue: {}, eventName: 'welcome_view_reports_clicked' })
      }

      window.location.href = this.checkoutFormUrl.formUrl
    },
    ...mapActions([
      'createPaymentFormUrl',
      'createSubscriptionFormUrl',
      'getCreditCardPackages',
      'getMailTrackLogDetail',
      'getSmsTrackLogDetail',
    ]),
  },
  computed: {
    isWelcomeFlow () {
      return this.$route.name === 'welcome'
    },
    isTrialPackage () {
      return !!this.packageData?.trialPeriod
    },
    getUserPhone () {
      if (this.isWelcomeFlow) {
        return `+${this.tempUserData.phone}`
      }

      return `+${this.userData?.phone}`
    },
    getPeriodTypeText () {
      if (this.packageData.period === 30) {
        return this.$t('period.month')
      }
      if (this.packageData.period === 365) {
        return this.$t('period.year')
      }

      return this.$t('period.day')
    },
    getTrialPeriodTypeText () {
      if (this.packageData.trialPeriod === 30) {
        return this.$t('period.month')
      }
      if (this.packageData.trialPeriod === 365) {
        return this.$t('period.year')
      }

      return this.$t('period.day')
    },
    getButtonText () {
      if (this.isWelcomeFlow) {
        if (this.isTrialPackage) {
          return this.$t('checkout_landing.view_reports')
        }

        return this.$t('checkout_landing.view_reports_with_price', {
          price: this.packageData.price,
          currency: this.packageData.currency,
          period: this.getPeriodTypeText,
        })
      }

      return this.$t('checkout_landing.continue_view_reports')
    },
    getPackageDiscountText () {
      return this.$t('checkout_landing.discount_text', {
        price: ((100 * this.packageData.price) / 28).toFixed(2),
        currency: this.packageData.currency,
        percent: '72',
      })
    },
    getExpireText () {
      if (this.isTrialPackage) {
        return this.$t('checkout_landing.expire_ten_minutes')
      }

      return this.$t('checkout_landing.expire_fifteen_minutes')
    },
    getAfterTrialPackageDetailText () {
      return this.$t('checkout_landing.after_trial_period_package_detail', {
        price: this.packageData.price,
        currency: this.packageData.currency,
        period: this.getPeriodTypeText,
      })
    },
    ...mapState([
      'user',
      'accessToken',
      'tempUserData',
    ]),
  },
}
</script>

<style lang="scss" src="./CheckoutLanding.scss"></style>
