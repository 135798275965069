<template>
  <div
    id="pk-header"
    :class="[
      'pk-header',
      {
        'logged-in': isLoggedIn,
        'with-back-button': isWithBackButton,
        'only-logo': isOnlyLogo,
        'hide-navbar': isHideNavbar
      }
    ]"
  >
    <div class="left-side">
      <i
        class="el-icon-back"
        @click="handleBackClick"
      >
      </i>

      <img
        class="parentskit-logo"
        v-scroll-to="'#pk-header'"
        src="../../assets/svg/parentskit-logo.svg"
        alt=""
        @click="handleLogoClick"
      />

      <img
        class="parentskit-logo-without-text"
        v-scroll-to="'#pk-header'"
        src="../../assets/svg/parentskit-logo-2.svg"
        alt=""
        @click="handleLogoClick"
      />
    </div>

    <div class="right-side">
      <template v-if="isLoggedIn">
        <div class="header-title">
          {{ $t(viewTitle) }}
        </div>

        <el-dropdown trigger="click">
          <user-avatar></user-avatar>

          <el-dropdown-menu
            class="pk-navbar-dropdown"
            slot="dropdown"
          >
            <!-- <el-dropdown-item>
              <div @click="handleMenuItemClick('profile')">
                <img
                  src="../../assets/svg/user.svg"
                  alt=""
                />
                {{ $t('navbar.profile') }}
              </div>
            </el-dropdown-item> -->

            <el-dropdown-item class="menu-item">
              <div @click="handleMenuItemClick('reports')">
                <img
                  src="../../assets/svg/reports.svg"
                  alt=""
                />
                {{ $t('navbar.title.reports') }}
              </div>
            </el-dropdown-item>

            <el-dropdown-item class="menu-item">
              <div @click="handleMenuItemClick('following')">
                <img
                  src="../../assets/svg/following.svg"
                  alt=""
                />
                {{ $t('navbar.title.following') }}
              </div>
            </el-dropdown-item>

            <el-dropdown-item class="menu-item">
              <div @click="handleMenuItemClick('subscriptions')">
                <img
                  src="../../assets/svg/subscriptions.svg"
                  alt=""
                />
                {{ $t('navbar.title.subscriptions') }}
              </div>
            </el-dropdown-item>

            <el-dropdown-item class="menu-item">
              <div @click="handleMenuItemClick('faq')">
                <img
                  src="../../assets/svg/help.svg"
                  alt=""
                />
                {{ $t('navbar.title.faq') }}
              </div>
            </el-dropdown-item>

            <el-dropdown-item>
              <div @click="handleLogoutClick">
                <img
                  src="../../assets/svg/logout.svg"
                  alt=""
                />
                {{ $t('navbar.logout') }}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>

      <template v-if="!isLoggedIn">
        <i
          class="el-icon-menu"
          @click.stop="handleMenuClick"
        ></i>
        <div :class="['menu-container', { open: isMenuOpen }]">
          <div
            class="menu-item"
            v-scroll-to="'#homeList'"
          >
            {{ $t('navbar.features') }}
          </div>

          <div
            class="menu-item"
            v-scroll-to="'#help'"
          >
            {{ $t('navbar.help') }}
          </div>

          <el-button
            class="menu-item"
            type="text"
            @click="handlePricingClick"
          >
            {{ $t('navbar.pricing') }}
          </el-button>

          <el-button
            class="menu-item login-button"
            @click="handleLoginClick"
          >
            {{ $t('navbar.login') }}
          </el-button>

          <div class="menu-item language">
            <img
              src="../../assets/images/globe.svg"
              alt=""
            />

            <el-select
              v-model="language"
              @change="changeLang">
              <el-option
                v-for="item in languages"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </template>
    </div>

    <verify-kit-dialog
      v-if="!isLoggedIn"
      :isVisible="isVerifyKitDialogVisible"
      @update-visible="isVerifyKitDialogVisible = $event"
    ></verify-kit-dialog>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex'
import UserAvatar from '@/components/user-avatar/UserAvatar'
import VerifyKitDialog from '@/components/verifyKitDialog/VerifyKitDialog'

export default {
  name: 'Navbar',
  components: {
    UserAvatar,
    VerifyKitDialog,
  },
  data () {
    return {
      isMenuOpen: false,
      isLoading: false,
      isVerifyKitDialogVisible: false,
      languages: [{
        value: 'en',
        label: 'EN',
      }, {
        value: 'tr',
        label: 'TR',
      }, {
        value: 'ru',
        label: 'RU',
      }, {
        value: 'ua',
        label: 'UA',
      }],
    }
  },
  mounted () {
    this.$parent.$el.addEventListener('click', this.handleDocumentClick)
  },
  beforeDestroy () {
    this.$parent.$el.removeEventListener('click', this.handleDocumentClick)
  },
  methods: {
    handleDocumentClick () {
      this.isMenuOpen = false
    },
    handleMenuClick () {
      this.isMenuOpen = !this.isMenuOpen
    },
    changeLang (lang) {
      this.$i18n.locale = lang
      this.changeLanguage(lang)
    },
    handlePricingClick () {
      this.$router.push({ name: 'pricing' })
    },
    handleLoginClick () {
      // this.isVerifyKitDialogVisible = true
      this.$router.push({
        name: 'welcome',
        query: {
          isnavbarhide: true,
          step: 'phone-number-form',
        },
      })
    },
    handleBackClick () {
      this.$router.go(-1)
    },
    handleLogoClick () {
      if (!this.isLoggedIn && this.$route.name !== 'home') {
        this.$router.push({ name: 'home' })
      } else if (this.isLoggedIn && this.$route.name !== 'reports') {
        this.$router.push({ name: 'reports' })
      }
    },
    handleMenuItemClick (name) {
      if (this.$route.name !== name) {
        this.$router.push({ name })
      }
    },
    handleLogoutClick () {
      this.logout()
    },
    ...mapActions([
      'changeLanguage',
      'logout',
    ]),
  },
  computed: {
    viewTitle () {
      return this.$route.matched
        ?.find(item => item.path === this.$route.path)
        ?.meta?.title ?? ''
    },
    isWithBackButton () {
      const routes = ['follow', 'payment']
      return routes.includes(this.$route.name)
    },
    isOnlyLogo () {
      const routes = [
        'verify-email',
        'new-checkout',
        'purchase-policy',
        'privacy-policy',
        'terms-of-use',
        'payment-success',
        'welcome',
        'pricing',
      ]
      return routes.includes(this.$route.name)
    },
    isHideNavbar () {
      return this.$route.query.isnavbarhide
    },
    ...mapState(['language']),
    ...mapGetters(['isLoggedIn']),
  },
}
</script>

<style lang="scss" src="./Navbar.scss"></style>
