<template>
  <el-dialog
    :title="$t('subscriptions.report_problem')"
    :fullscreen="isMobile"
    :visible.sync="isVisible"
    :before-close="handleClose"
    custom-class="report-problem-dialog"
  >

  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    @submit.native.prevent
  >
    <el-form-item
      prop="type"
      :label="$t('subscriptions.problem_type')"
    >
      <el-select
        popper-class="problem-select-popper"
        :placeholder="$t('subscriptions.please_choose')"
        v-model="form.type"
      >
        <el-option
          v-for="item in typeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item
      prop="reason"
      :label="$t('subscriptions.note')"
    >
      <el-input
        :rows="2"
        type="textarea"
        :placeholder="$t('subscriptions.write_your_problem')"
        v-model="form.reason">
      </el-input>
    </el-form-item>
  </el-form>

  <template slot="footer">
    <el-button
      class="cancel-button"
      :loading="isLoading"
      @click="handleClose"
    >
      {{ $t('form.give_up') }}
    </el-button>
    <el-button
      class="confirm-button"
      type="primary"
      :loading="isLoading"
      @click="handleConfirmClick"
    >
      {{ $t('form.send') }}
    </el-button>
  </template>
</el-dialog>
</template>

<script>
export default {
  name: 'ReportProblemDialog',
  props: {
    isLoading: {
      required: true,
      default: false,
    },
    isVisible: {
      required: true,
      default: false,
    },
  },
  data () {
    return {
      form: {
        type: null,
        reason: '',
      },
      rules: {
        type: [{
          required: true,
          message: this.$t('validation.required_field'),
          trigger: 'submit',
        }],
        reason: [{
          required: true,
          message: this.$t('validation.required_field'),
          trigger: 'submit',
        }, {
          min: 10,
          message: this.$t('validation.min_characters', { count: 10 }),
          trigger: 'submit',
        }],
      },
      typeOptions: [{
        value: '43',
        label: this.$t('subscriptions.problem_43'),
      }, {
        value: '44',
        label: this.$t('subscriptions.problem_44'),
      }, {
        value: '45',
        label: this.$t('subscriptions.problem_45'),
      }, {
        value: '46',
        label: this.$t('subscriptions.problem_46'),
      }, {
        value: '47',
        label: this.$t('subscriptions.problem_47'),
      }, {
        value: '5',
        label: this.$t('subscriptions.problem_5'),
      }],
    }
  },
  methods: {
    handleClose () {
      this.$emit('update-visible', false)

      this.form = {
        type: null,
        reason: '',
      }
    },
    handleConfirmClick () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$emit('handle-report-problem', {
            type: this.form.type,
            reason: this.form.reason.trim(),
          })

          this.form = {
            type: null,
            reason: '',
          }
        }
      })
    },
  },
  computed: {
    isMobile () {
      return window.innerWidth < 480
    },
  },
}
</script>

<style lang="scss" src="./ReportProblemDialog.scss"></style>
