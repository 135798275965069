<template>
  <div class="info-container">
    <div class="title">
      {{ $t('welcome.instant_online_info') }}
    </div>

    <div class="text">
      {{ $t('welcome.when_online') }}
    </div>

    <div class="text">
      {{ $t('welcome.how_long_online') }}
    </div>

    <div class="text">
      {{ $t('welcome.can_follow_number') }}
    </div>

    <el-button
      round
      type="success"
      @click="handleButtonClick"
    >
      {{ $t('welcome.start_now') }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'Info',
  created () {
    if (window.AF) {
      window.AF('pba', 'event', { eventType: 'EVENT', eventValue: {}, eventName: 'welcome_open' })
    }
  },
  methods: {
    handleButtonClick () {
      this.$emit('handle-update-step', 'phone-number-form')
      this.$router.replace({
        query: { isnavbarhide: true },
      })
    },
  },
}
</script>

<style lang="scss" src="./Info.scss"></style>
