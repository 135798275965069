import { BaseProxy } from './BaseProxy'

export class PricingProxy extends BaseProxy {
  constructor ({ parameters = {} } = {}) {
    super({
      endpoint: 'v2/price',
      parameters,
    })
  }
}
