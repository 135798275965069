import { BaseProxy } from './BaseProxy'

export class LogsFilterProxy extends BaseProxy {
  constructor ({ parameters = {} } = {}) {
    super({
      endpoint: 'stats/logs/filter',
      parameters,
    })
  }
}
