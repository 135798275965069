<template>
  <el-menu
    class="pk-menu"
    :default-active="defaultActive"
  >
    <el-menu-item index="1">
      <div @click="handleMenuItemClick('reports')">
        <img
          src="../../assets/svg/reports.svg"
          alt=""
        />
        <span slot="title">
          {{ $t('navbar.title.reports') }}
        </span>
      </div>
    </el-menu-item>

    <el-menu-item index="2">
      <div @click="handleMenuItemClick('following')">
        <img
          src="../../assets/svg/following.svg"
          alt=""
        />
        <span slot="title">
          {{ $t('navbar.title.following') }}
        </span>
      </div>
    </el-menu-item>

    <el-menu-item index="3">
      <div @click="handleMenuItemClick('subscriptions')">
        <img
          src="../../assets/svg/subscriptions.svg"
          alt=""
        />
        <span slot="title">
          {{ $t('navbar.title.subscriptions') }}
        </span>
      </div>
    </el-menu-item>

    <el-menu-item index="4">
      <div @click="handleMenuItemClick('faq')">
        <img
          src="../../assets/svg/help.svg"
          alt=""
        />
        <span slot="title">
          {{ $t('navbar.title.faq') }}
        </span>
      </div>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: 'Menu',
  data () {
    return {
      isCollapse: false,
    }
  },
  methods: {
    handleMenuItemClick (name) {
      if (this.$route.name !== name) {
        this.$router.push({ name })
      }
    },
  },
  computed: {
    defaultActive () {
      let activeKey

      switch (this.$route.name) {
      case 'reports':
        activeKey = '1'
        break
      case 'following':
        activeKey = '2'
        break
      case 'subscriptions':
        activeKey = '3'
        break
      case 'faq':
        activeKey = '4'
        break
      default:
        activeKey = '-1'
      }

      return activeKey
    },
  },
}
</script>

<style lang="scss" src="./Menu.scss"></style>
