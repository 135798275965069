<template>
  <div class="welcome-container">
    <component
      :is="currentComponent"
      @handle-update-step="handleUpdateStep"
    >
    </component>
  </div>
</template>

<script>
import { hasStorage } from '@/utils/localStorageHelper'
import { welcomeStepsMap } from '@/constants/welcome-steps'

export default {
  name: 'Welcome',
  data () {
    return {
      step: 'info',
    }
  },
  created () {
    if (hasStorage('accessToken')) {
      this.$router.replace({ name: 'reports' })
    }

    const { step } = this.$route.query
    if (step) {
      this.handleUpdateStep(step)
    }
  },
  methods: {
    handleUpdateStep (value) {
      this.step = value
    },
  },
  computed: {
    currentComponent () {
      return welcomeStepsMap.get(this.step)
    },
  },
}
</script>

<style lang="scss" src="./Welcome.scss"></style>
