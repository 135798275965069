<template>
  <div :class="['faq-container', { 'is-home': isHome }]">
    <h2>{{ $t('faq.have_questions') }}</h2>
    <el-collapse accordion>
      <el-collapse-item name="1">
        <template slot="title">
          <h3>{{ $t('faq.question_one') }}</h3>
        </template>
        <div class="answerBlock">
          <p>{{ $t('faq.answer_one') }}</p>
        </div>
      </el-collapse-item>

      <el-collapse-item name="2">
        <template slot="title">
          <h3>{{ $t('faq.question_two') }}</h3>
        </template>
        <div class="answerBlock">
          <p>{{ $t('faq.answer_two') }}</p>
        </div>
      </el-collapse-item>

      <el-collapse-item name="3">
        <template slot="title">
          <h3>{{ $t('faq.question_three') }}</h3>
        </template>
        <div class="answerBlock">
          <p>{{ $t('faq.answer_three') }}</p>
        </div>
      </el-collapse-item>

      <el-collapse-item name="4">
        <template slot="title">
          <h3>{{ $t('faq.question_four') }}</h3>
        </template>
        <div class="answerBlock">
          <p>{{ $t('faq.answer_four') }}</p>
        </div>
      </el-collapse-item>

      <el-collapse-item name="5">
        <template slot="title">
          <h3>{{ $t('faq.question_five') }}</h3>
        </template>
        <div class="answerBlock">
          <p>{{ $t('faq.answer_five') }}</p>
        </div>
      </el-collapse-item>
    </el-collapse>

    <div
      class="other-questions"
      v-html="$t('faq.other_questions')"
    >
    </div>
  </div>
</template>
<script>
export default {
  name: 'Faq',
  computed: {
    isHome () {
      return this.$route.name === 'home'
    },
  },
}
</script>

<style lang="scss" src="./Faq.scss"></style>
