import Vue from 'vue'
import App from './App.vue'
import { router } from '@/plugins/vue-router'
import { i18n } from '@/plugins/vue-i18n'
import '@/plugins/index'
import store from './store'
import './registerServiceWorker'
import './configFirebase'
import './design/css/main.css'
import './design/sass/main.scss'
import {
  hasStorage,
  getStorage,
  clearEmptyStorage,
} from '@/utils/localStorageHelper'

function setLanguage () {
  let language = new URLSearchParams(window.location.search).get('language')

  if (language) {
    i18n.locale = language
  } else if (hasStorage('language')) {
    language = getStorage('language')
  } else {
    language = 'en'
  }

  i18n.locale = language
  store.dispatch('changeLanguage', language)
}

function setInitialRoute () {
  if (hasStorage('refreshToken') && hasStorage('userId')) {
    if (router.history._startLocation === '/') {
      router.replace({ name: 'dashboard' })
    }
  }
}

async function checkAuthentication () {
  if (hasStorage('refreshToken') && hasStorage('userId')) {
    await store.dispatch('refreshToken')
    await store.dispatch('getUserInfo')
  }
}

clearEmptyStorage()
setLanguage()
// store.dispatch('registerDevice')
setInitialRoute()
checkAuthentication()

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
