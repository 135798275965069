import firebase from 'firebase/app'
import 'firebase/messaging'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
const uuid = uuidv4()

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyCJ9BZzdHaQzMzfywszErdzzDN7M_45IiY',
  authDomain: 'parentskit.firebaseapp.com',
  databaseURL: 'https://parentskit.firebaseio.com',
  projectId: 'parentskit',
  storageBucket: 'parentskit.appspot.com',
  messagingSenderId: '287324345372',
  appId: '1:287324345372:web:a892c837a7a9c5c3303bd9',
}

firebase.initializeApp(config)
// Retrieve Firebase Messaging object.

if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging()
  const key =
    'BOFG5lY0Uz3Ov8AOGyTRORjJsAZ7JoBk_N6PQq8CsYOggaDs7PNzc72cUSb9KZtJMm43URSqI4in7Va6dH5v-Mg'
  messaging.usePublicVapidKey(key)

  messaging
    .requestPermission()
    .then(() => {
      messaging.getToken().then((token) => {
        localStorage.setItem('notificationToken', token)
        if (localStorage.getItem('notificationToken')) {
          // console.log('');
        } else {
          let notification = localStorage.getItem('notificationToken')
          const userData = JSON.stringify({
            deviceId: uuid,
            googleAdvertisingId: '',
            pushId: notification,
            platform: 3,
          })
          axios.post('/devices', userData)
        }
      })
    })
    .catch()
} else {
  // console.log('no supported');
}

/*
messaging.onMessage(response => {
     console.log('response: ', response);
});
*/
