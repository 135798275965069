<template>
  <div class="terms-of-use-container">
    <div class="terms-of-use-box">
      <h2>ParentsKit Terms of Service</h2>

      <p>ParentsKit(“ParentsKit” “our,” “we,” or “us”) is a service that provides parental control to users. With ParentsKit, you can track your child’s online activities simultaneously by receiving notifications.</p>
      <p>Terms of Service here in is owned and operated by Lambda Mobile Apps which enable the use of applications by Jetapps Yazilim Hizmetleri Sanayi Ve Ticaret Limited Şirketi shall be valid for all services given to you by Jetapps Yazilim Hizmetleri Sanayi Ve Ticaret Limited Şirketi.</p>
      <p>Please read our Terms of Service so you can clearly understand how we operate and what conduct we expect from you when using our service. You agree to our Terms of Service (“Terms”) by accessing, or using the ParentsKit, features, software, or website (together, “Services”).</p>
      <p>On the condition that you comply with all your obligations under these Terms, including, but not limited to, the Developer Code of Conduct listed below, we grant you a limited, revocable, nonexclusive, nonassignable, nonsublicenseable license and right to utilise our Services.</p>
      <p>Any other use contrary to our purpose is strictly prohibited and a violation of the Terms. We reserve all rights not expressly granted in the Terms, including, without limitation, title, ownership, intellectual property rights, and all other rights and interest in our Services, and all related items.</p>
      <p><b>Last Update:</b> 04 June 2020</p>

      <h4>Termination</h4>
      <p>
        To be eligible to use the Services, you must meet the following criteria and represent and warrant that you:
        <ul class="disc">
          <li>are 18 years of age or older;</li>
          <li>are not currently residing in a prohibited territory restricted from the Services</li>
          <li>have full power and authority to enter into our Terms;</li>
          <li>will not violate any rights of ParentsKit including intellectual property rights such as copyright or trademark rights; and</li>
          <li>agree to provide at your cost all equipment, software, and internet access necessary to use the Services.</li>
        </ul>
      </p>

      <h4>Privacy</h4>
      <p>You acknowledge that you have read our Privacy Policy. Ourmost recent updated Privacy Policy can be found <a target="_blank" href="https://parentskit.me/privacy-policy">here</a>.</p>

      <h4>Developer Code of Conduct</h4>
      <p>
        Any fraudulent, abusive, or otherwise illegal activity, or violation of our Developer Code of Conduct is grounds for termination at our sole discretion, and you may be reported to appropriate authorities. We do not tolerate any of the following conduct:
        <ul class="disc">
          <li>Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for any underlying intellectual property used to provide our Services;</li>
          <li>Adapt, modify or create derivative works based on the Services or any underlying technology;</li>
          <li>Remove any copyright, trademark or other proprietary rights notices contained in or on our Services;</li>
          <li>Collect, use, copy, sell, distribute or transfer any information, including, but not limited to, Personally Identifiable Information obtained our Services except as expressly permitted in the Terms or as the owner of such information may expressly permit;</li>
          <li>Use manual or automated software, devices, scripts, bots, robots, other means or processes to access, “scrape,” “crawl” or “spider” the Services or to add or download contacts;</li>
          <li>Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of the Services; and</li>
          <li>Engage in any action that directly or indirectly interferes with the integrity of our Services, including but not limited to any attempts to access or hack our servers or network.</li>
        </ul>
      </p>

      <h4>Indemnification</h4>
      <p>You indemnify us and hold us harmless for all damages, losses and costs (including, but not limited to, reasonable legal fees and costs) related to all third party claims, charges, and investigations, caused by (1) your failure to comply with our Terms, including, without limitation, that anything you submit or contribute violate the rights of any third party or applicable laws; (2) any use or content you submit via our Services; (3) any activity in which you engage on or through the Services and (4) any misrepresentation made by you. You will cooperate as fully as required by us in the defense or settlement of any claim.</p>
      <p>You agree to indemnify and hold ParentsKit, and its subsidiaries, affiliates, officers, agents or other partners, and employees, harmless from any claim or demand, including reasonable legal fees, made by any third party due to or arising out of content, data, or information you submit, contribute, post to or transmit to or through the Services and your use of or connection to same, your violation of the Terms of Use, or your violation of any rights of another person or entity.</p>

      <h4>Disclaimer of Warranties</h4>
      <p>EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN A WRITTEN AGREEMENT BETWEEN YOU AND PARENTSKIT, OUR SERVICES ARE PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, PARENTSKIT DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES, FITNESS FOR A PARTICULAR PURPOSE, DATA ACCURACY, TITLE, QUIET ENJOYMENT OR THE WARRANTY OF NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, PARENTSKIT DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN OR AVAILABLE ON, IN OR THROUGH PARENTSKİT AND OR ANY SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR OUR SERVICES OR THE SERVERS THAT MAKE SUCH AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. PARENTSKİT DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE SERVICES IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. ALTHOUGH PARENTSKİT MAKES EFFORTS TO PREVENT PERSONALLY IDENTIFIABLE INFORMATION FROM BEING DISCLOSED, PARENTSKIT MAKES NO WARRANTY FOROTHER USERS WHO SUBMIT CONTACT INFORMATION FOR YOU AND OTHERS. AS PART OF THE CUSTOMER VERIFICATION ASPECT OF OUR SERVICES, APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. WITHOUT LIMITATION OF THE FOREGOING, YOU ACKNOWLEDGE THAT AS A SERVICE, WE MAY INCLUDE LINKS TO OTHER THIRD PARTIES AND THAT PARENTSKIT HAS NO CONTROL OVER, AND MAKES NO REPRESENTATIONS OF ANY KIND WHATSOEVER, REGARDING THE CONTENT OR CONDUCT OF SUCH THIRD PARTIES AND YOU HERE BY IRREVOCABLY WAIVE ANY CLAIM AGAINST USRELATED TO THIRD PARTIES.</p>

      <h4>Limitation of Liability</h4>
      <p>NOTHING IN THESE TERMS OF USE EXCLUDES OR LIMITS OUR LIABILITY FOR DEATH OR PERSONAL INJURY ARISING FROM OUR NEGLIGENCE, OR OUR FRAUD OR FRAUDULENT MISREPRESENTATION, OR ANY OTHER LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED BY TURKISH LAW.</p>
      <p>TO THE EXTENT PERMITTED BY LAW, WE EXCLUDE ALL CONDITIONS, WARRANTIES, REPRESENTATIONS OR OTHER TERMS WHICH MAY APPLY TO OUR SERVICES OR ANY CONTENT ON IT, WHETHER EXPRESS OR IMPLIED.</p>
      <p>WE WILL NOT BE LIABLE TO ANY USER FOR ANY LOSS OR DAMAGE, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), BREACH OF STATUTORY DUTY, OR OTHERWISE, EVEN IF FORESEEABLE, ARISING UNDER OR IN CONNECTION WITH USE OF, OR INABILITY TO USE THE PARENTSKIT SERVICES.</p>

      <h4>Misuse of our Services</h4>
      <p>ParentsKit along with its respective partners and affiliates reserve the right to investigate suspected violations of our Terms and may seek to gather information from the Developer or User who is suspected of violation, and from any other user. We will collect and use any such information in accordance with our Privacy Policy.</p>
      <p>In its sole discretion, we may restrict, suspend or terminate the account of any User who abuses or misuses the Services. Misuse for example may include, without limitation, creating multiple or false accounts; infringing any intellectual property rights, violating any aspect of the Developer Code of Conduct, or any other behaviour that ParentsKit, in its sole discretion, deems inappropriate.</p>
      <p>BY ACCEPTING OUR TERMS OF USE, YOU WAIVE AND HOLD HARMLESS PARENTSKIT, ITS RESPECTIVE PARTNERS AND AFFILIATES FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY PARENTSKIT DURING OR AS A RESULT OF ITS INVESTIGATIONS AND/OR FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER PARENTSKIT OR LAW ENFORCEMENT AUTHORITIES.</p>

      <h4>General Terms</h4>
      <ol>
        <li>
          <b>Terms of use</b>
          <ol>
            <li>Please read these General Terms carefully before you start to use our Services.</li>
            <li>By using our Services, you confirm that you accept our Terms and that you agree to comply with them.</li>
            <li>If you do not agree to these terms of use, please do not integrate, install or register for the ParentsKit Services.</li>
          </ol>
        </li>
        <li>
          <b>Other applicable terms</b>
          <ol>
            <li>Your use of our Services is subject to these terms in their entirety and by using our Services you agree to be bound by them.</li>
            <li>You need to register with us to fully access our Services and provide as a minimum a registered company name, real name, e-mail address, and mobile number. The details provided by you on registration or changed at any later time must be correct and complete.</li>
            <li>Our use of your personal information is governed by our Privacy Policy found <a target="_blank" href="https://parentskit.me/privacy-policy">here</a>.</li>
          </ol>
        </li>
        <li>
          <b>Severability</b>
          <ol>
            <li>Each of the provisions of these Terms are distinct and severable from the others. If at any time one or more of those provisions is or become invalid, unlawful or unenforceable (whether wholly or partly), the validity, lawfulness and enforceability of the remaining provisions (or the same provision to any other extent) will not be affected or impaired in any way.</li>
            <li>We may agree to amend these Terms of use in order to ensure the terms are valid, lawful and enforceable.</li>
          </ol>
        </li>
        <li>
          <b>Information about us</b>
          <ol>
            <li>ParentsKit is Jetapps Yazilim Hizmetleri Sanayi Ve Ticaret Limited Şirketi’s product.</li>
          </ol>
        </li>
        <li>
          <b>Changes to these terms</b>
          <ol>
            <li>We may revise these Terms at any time by amending this page.</li>
            <li>Please check this page from time to time to take notice of any changes we made, as they are binding on you.</li>
          </ol>
        </li>
        <li>
          <b>Accuracy of our Services</b>
          <ol>
            <li>We cannot guarantee that our Services, or any content on it, will be free from errors or omissions.</li>
          </ol>
        </li>
        <li>
          <b>Accessing our Services</b>
          <ol>
            <li>We do not guarantee that our Services will always be available or be uninterrupted.</li>
            <li>You are responsible for making all arrangements necessary for you to have access to our Services.</li>
            <li>We do not represent that our Services will be available in other locations. We may limit the availability of our Services or specific functionality to any user or geographic area at any time. If you choose to access our Services from a prohibited jurisdiction, you do so at your own risk.</li>
          </ol>
        </li>
        <li>
          <b>Intellectual property rights</b>
          <ol>
            <li>For the purposes of these Terms, "Intellectual Property Rights" means all patents, rights to inventions, utility models, copyright and related rights, trademarks, service marks, trade, business and domain names, rights in trade dress or get-up, rights in goodwill or to sue for passing off, unfair competition rights, rights in designs, rights in computer software, database rights, semi-conductor topography rights, moral rights, rights in confidential information (including know-how and trade secrets) and any other intellectual property rights, in each case whether registered or unregistered and including all applications for and renewals or extensions of such rights, and all similar or equivalent rights or forms of protection in any part of the world.</li>
            <li>Our Services are protected by copyright laws and international copyright treaties, as well as other intellectual property laws.</li>
            <li>Our trademarks may not be used in connection with any product or service that is not ours, in any manner that is likely to cause confusion among customers, or in any manner that has a detrimental effect on ParentsKit. All other trademarks not owned by us that may appear on our Services are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by us.</li>
          </ol>
        </li>
        <li>
          <b>Viruses</b>
          <ol>
            <li>We do not guarantee that our site will be secure or free from bugs or viruses.</li>
            <li>You are responsible for configuring your mobile device in order to access our Services. You should ensure your hardware is adequately protected with suitably updated virus protection software.</li>
          </ol>
        </li>
        <li>
          <b>Third party links</b>
          <ol>
            <li>Where our Services may contain links to other sites and resources provided by third parties, these links are provided for your information only</li>
            <li>We have no control over the contents of those third-party sites or resources.</li>
          </ol>
        </li>
        <li>
          <b>Applicable law</b>
          <ol>
            <li>You and we both agree to that the courts of the Republic of Turkey will have exclusive jurisdiction.</li>
          </ol>
        </li>
        <li>
          <b>Accessing our Terms in different languages</b>
          <ol>
            <li>We provide our Services internationally; additional Terms of Services may apply to Users in different jurisdictions and where possible we do our best to offer localised Terms for your territory. Please check for our Terms in other languages on our site or in application if available. If not available, our Terms will default to the English language version.</li>
          </ol>
        </li>
        <li>
          <b>Contact us</b>
          <ol>
            <li>To contact us, please email <a href="mailto:support@parentskit.me">support@parentskit.me</a></li>
          </ol>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsOfUse',
}
</script>

<style lang="scss" src="./TermsOfUse.scss"></style>
