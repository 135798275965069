<template>
  <div :class="['following-card-container', { 'is-add-new': isAddNew }]">
    <div
      v-if="description && description.trim().length"
      class="description"
    >
      {{ description }}
    </div>

    <img
      v-if="isAddNew"
      class="circle-add"
      src="../../assets/svg/circle-add.svg"
      alt=""
      @click="handleAddClick"
    />

    <template v-if="!isAddNew">
      <i
        class="el-icon-close"
        @click="handleUnfollowClick"
      >
      </i>

      <user-avatar></user-avatar>

      <div class="user-name">
        {{ getUserName }}
      </div>

      <div :class="['user-status', { 'active': isUserActive, 'inactive': !isUserActive }]">
        <template v-if="isUserActive">
          {{ $t('following.active') }}
        </template>
        <template v-else>
          {{ $t('following.inactive') }}
        </template>
      </div>

      <div class="last-seen">
        <div class="text">
          {{ $t('form.last_seen') }}
        </div>

        <div class="date-time">
          {{ getLastSeenDate | formatDate }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import UserAvatar from '@/components/user-avatar/UserAvatar'
import dayjs from 'dayjs'
import {
  hasStorage,
  getStorage,
} from '@/utils/localStorageHelper'

export default {
  name: 'FollowingCard',
  components: {
    UserAvatar,
  },
  props: {
    user: { default: null },
    isAddNew: { default: false },
    description: { default: '' },
  },
  filters: {
    formatDate (value) {
      if (value) {
        const language = hasStorage('language') ? getStorage('language') : 'en'
        return dayjs(value).locale(language).format('llll')
      }
    },
  },
  methods: {
    handleAddClick () {
      this.$emit('handle-follow')
    },
    handleUnfollowClick () {
      this.$emit('handle-unfollow', this.user?._id)
    },
  },
  computed: {
    getUserName () {
      return this.user?.name
    },
    isUserActive () {
      return !!this.user?.trackingEnabled
    },
    getLastSeenDate () {
      return this.user?.created
    },
  },
}
</script>

<style lang="scss" src="./FollowingCard.scss"></style>
