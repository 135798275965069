<script>
import { mapActions } from 'vuex'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation'

export default {
  name: 'ManageSubscription',
  components: {
    LottieAnimation,
  },
  data () {
    return {
      loadingAnimation: null,
    }
  },
  created () {
    const { userId } = this.$route.query
    if (userId) {
      this.sendRequestForCreateUrl(userId)
    } else {
      this.$router.replace({ name: 'home' })
    }
  },
  methods: {
    async sendRequestForCreateUrl (userId) {
      this.loadingAnimation = this.$loading({
        lock: true,
        text: this.$t('loading_text'),
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: 'loading-style',
      })

      try {
        const response = await this.createManageSubscriptionUrl({userId})
        this.loadingAnimation.close()
        window.location.href = response.url
      } catch (error) {
        this.loadingAnimation.close()
        this.$router.replace({ name: 'home' })
      }
    },
    ...mapActions(['createManageSubscriptionUrl']),
  },
}
</script>
