import { BaseProxy } from './BaseProxy'

export class UserProxy extends BaseProxy {
  constructor ({ parameters = {} } = {}) {
    super({
      endpoint: 'info/user',
      parameters,
    })
  }
}
