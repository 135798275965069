<template>
  <div class="phone-number-form-container">
    <div class="title">
      {{ $t('welcome.verify_phone_number') }}
    </div>

    <div class="subtitle">
      {{ $t('welcome.can_get_report_sms') }}
    </div>

    <label class="input-label">
      {{ $t('welcome.to_get_started') }}
    </label>

    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      @submit.native.prevent
    >
      <el-form-item prop="phoneNumber">
        <vue-phone-number-input
          show-code-on-list
          :disabled="isLoading"
          :error="isPhoneNumberError"
          v-model="form.phoneNumber"
          :default-country-code="getDefaultCountry"
          @update="handlePhoneNumberChange"
        >
        </vue-phone-number-input>
      </el-form-item>

      <el-form-item>
        <el-button
          round
          type="success"
          :loading="isLoading"
          @click="handleSubmitClick"
        >
          {{ $t('welcome.continue') }}
        </el-button>
      </el-form-item>
    </el-form>

    <div class="info">
      <div v-html="$t('welcome.privacy_policy_and_terms_of_service')"></div>
      <div>
        {{ $t('welcome.communicate_sms') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getDefaultDetectedCountry } from '@/utils'

export default {
  name: 'PhoneNumberForm',
  data () {
    return {
      isLoading: false,
      form: {
        phoneNumber: '',
      },
      rules: {
        phoneNumber: [{
          required: true,
          message: this.$t('validation.required_field'),
          trigger: 'submit',
        }],
      },
      phoneObj: null,
    }
  },
  created () {
    if (window.AF) {
      window.AF('pba', 'event', { eventType: 'EVENT', eventValue: {}, eventName: 'welcome_phone_verify' })
    }
  },
  methods: {
    handlePhoneNumberChange (val) {
      this.phoneObj = val
    },
    handleSubmitClick () {
      if (this.isLoading) return

      this.isLoading = true

      this.$refs.form.validate(async valid => {
        if (valid && !this.isPhoneNumberError) {
          try {
            const {
              countryCode,
              countryCallingCode,
              nationalNumber,
            } = this.phoneObj
            const response = await this.login({
              phoneNumber: `${countryCallingCode}${nationalNumber}`,
              attribution: 'organic',
              trackerName: 'organic',
              countryCode: countryCode,
              platform: 3,
            })

            if (response.flow === 'home') {
              this.$router.replace({ name: 'reports' })
            } else if (response.flow === 'email') {
              this.$emit('handle-update-step', 'email-form')
            } else {
              this.$emit('handle-update-step', 'verify-phone-number-form')
            }
          } catch (error) {
            this.isLoading = false
          }
        } else {
          this.isLoading = false
          return false
        }
      })
    },
    ...mapActions(['login']),
  },
  computed: {
    isPhoneNumberError () {
      return this.phoneObj?.phoneNumber?.length && !this.phoneObj?.isValid
    },
    getDefaultCountry () {
      return getDefaultDetectedCountry()
    },
  },
}
</script>

<style lang="scss" src="./PhoneNumberForm.scss"></style>
