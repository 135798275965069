import { BaseProxy } from './BaseProxy'

export class CreateSubscriptionFormUrlProxy extends BaseProxy {
  constructor ({ parameters = {} } = {}) {
    super({
      endpoint: 'subscriptions/createFormUrl/web',
      parameters,
    })
  }
}
