import Home from '@/views/Home'
import Dashboard from '@/views/dashboard/Dashboard'
import { dashboardRoutes } from './dashboard-routes'
import Follow from '@/views/follow/Follow'
import Payment from '@/views/payment/Payment'
import Checkout from '@/views/checkout/Checkout'
import NewCheckout from '@/views/new-checkout/NewCheckout'
import VerifyEmail from '@/views/verify-email/VerifyEmail'
import PurchasePolicy from '@/views/purchase-policy/PurchasePolicy'
import PrivacyPolicy from '@/views/privacy-policy/PrivacyPolicy'
import TermsOfUse from '@/views/terms-of-use/TermsOfUse'
import Welcome from '@/views/welcome/Welcome'
import PaymentSuccess from '@/views/payment-success/PaymentSuccess'
import SubscriptionsMobile from '@/views/subscriptions-mobile/SubscriptionsMobile'
import Pricing from '@/views/pricing/Pricing'
import ManageSubscription from '@/views/manage-subscription/ManageSubscription'

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { title: 'navbar.title.home' },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'navbar.title.reports',
      requiresAuth: true,
    },
    redirect: '/dashboard/reports',
    children: dashboardRoutes,
  },
  {
    path: '/follow',
    name: 'follow',
    component: Follow,
    meta: {
      title: 'navbar.title.follow',
      requiresAuth: true,
    },
  },
  {
    path: '/payment',
    name: 'payment',
    component: Payment,
    meta: {
      title: 'navbar.title.payment',
      // requiresAuth: true,
    },
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout,
  },
  {
    path: '/new-checkout',
    name: 'new-checkout',
    component: NewCheckout,
  },
  {
    path: '/verifyEmail',
    name: 'verify-email',
    component: VerifyEmail,
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: Welcome,
  },
  {
    path: '/payment-success',
    name: 'payment-success',
    component: PaymentSuccess,
  },
  {
    path: '/purchase-policy',
    name: 'purchase-policy',
    component: PurchasePolicy,
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: TermsOfUse,
  },
  {
    path: '/subscriptions-mobile',
    name: 'subscriptions-mobile',
    component: SubscriptionsMobile,
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: Pricing,
    meta: {
      title: 'navbar.title.pricing',
    },
  },
  {
    path: '/manage-subscription',
    name: 'manage-subscription',
    component: ManageSubscription,
  },
  {
    path :'*',
    redirect: '/',
  },
]
