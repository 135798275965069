<template>
  <img
    class="user-avatar"
    src="../../assets/svg/user-avatar.svg"
    alt=""
  />
</template>

<script>
export default {
  name: 'UserAvatar',
}
</script>

<style lang="scss" src="./UserAvatar.scss"></style>
